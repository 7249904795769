export const DEFAULT_ANALYTICS_EVENTS = {
  WEB_TIMELINE_CLICK: {},
  WEB_PHOTO_VIDEO_CLICK: {},
  WEB_PHOTO_REMOVE_CLICK: {},
  WEB_PHOTO_DOWNLOAD_CLICK: {},
  WEB_PHOTO_COPY_TO_FC_CLICK: {},
  WEB_PHOTO_NEW_ALBUM_CLICK: {},
  WEB_PHOTOS_CREATE_NEW_ALBUM: {
    firebase: {
      eventName: 'web_photos_create_new_album'
    }
  },
  WEB_FC_CREATE_NEW_ALBUM: {
    firebase: {
      eventName: 'web_fc_create_new_album'
    }
  },
  WEB_PHOTO_UPLOAD_CLICK: {},
  WEB_MY_ALBUMS_CLICK: {},
  WEB_ALBUM_REMOVE_CLICK: {},
  WEB_ALBUM_ADD_TO_ALBUM_CLICK: {},
  WEB_FC_TIMELINE_CLICK: {
    firebase: {
      eventName: 'web_fc_timeline_click'
    }
  },
  WEB_FC_PHOTO_UPLOAD_CLICK: {
    firebase: {
      eventName: 'web_fc_photo_upload_click'
    }
  },
  WEB_FC_ALBUMS_CLICK: {
    firebase: {
      eventName: 'web_fc_albums_click'
    }
  },
  WEB_FC_MANAGE_CLICK: {
    firebase: {
      eventName: 'web_fc_manage_click'
    }
  },
  WEB_FC_MANAGE_DELETE_FC_CLICK: {
    firebase: {
      eventName: 'web_fc_manage_delete_fc_click'
    }
  },
  WEB_FOLDER_CLICK: {},
  WEB_FILE_CLICK: {},
  WEB_FILE_SELECT: {},
  WEB_FOLDER_SELECT: {},
  WEB_UPLOAD_FILES_CLICK: {},
  WEB_UPLOAD_FOLDER_CLICK: {},
  WEB_CREATE_FOLDER_CLICK: {},
  WEB_FILE_MOVE_CLICK: {},
  WEB_FILE_COPY_CLICK: {},
  WEB_PUBLIC_LINKS_CLICK: {},
  WEB_PUBLIC_FILE_FOLDER_SELECT: {},
  WEB_PUBLIC_LINK_SETTINGS_CLICK: {},
  WEB_PUBLIC_ALBUMS_CLICK: {},
  WEB_PUBLIC_FC_ALBUMS_CLICK: {},
  WEB_TRASH_BIN_CLICK: {},
  WEB_EMPTY_TRASHBIN_CLICK: {},
  WEB_TRASHBIN_FULLRESTORE_CLICK: {},
  WEB_TRASHBIN_FOLDER_SELECT: {},
  WEB_TRASHBIN_FILE_SELECT: {},
  WEB_TRASHBIN_DELETE_CLICK: {},
  WEB_TRASHBIN_PHOTOS_CLICK: {},
  WEB_TRASHBIN_FC_PHOTOS_CLICK: {},
  WEB_GOOGLE_PLAY_CLICK: {},
  WEB_APP_STORE_CLICK: {},
  WEB_FEEDBACK_CLICK: {},
  WEB_PROFILE_SETTINGS_CLICK: {},
  WEB_LOGOUT_CLICK: {},
  WEB_PHOTOS_FAVORITES_CLICK: {
    firebase: {
      eventName: 'web_photos_favorites_click'
    }
  },
  WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES: {
    firebase: {
      eventName: 'web_photos_actions_add_to_favorites'
    }
  },
  WEB_PHOTOS_ACTIONS_REMOVE_FROM_FAVORITES: {
    firebase: {
      eventName: 'web_photos_actions_remove_from_favorites'
    }
  },
  WEB_FLASHBACK_VIEW: {
    firebase: {
      eventName: 'web_flashback_view'
    }
  },
  WEB_FLASHBACK_VIEW_NEXT: {
    firebase: {
      eventName: 'web_flashback_view_next'
    }
  },
  WEB_FLASHBACK_NEXT_PHOTO: {
    firebase: {
      eventName: 'web_flashback_next_photo'
    }
  },
  WEB_FLASHBACK_ADD_FAVORITE: {
    firebase: {
      eventName: 'web_flashback_add_favorite'
    }
  },
  WEB_FLASHBACK_REMOVE_FAVORITE: {
    firebase: {
      eventName: 'web_flashback_remove_favorite'
    }
  },

  WEB_DOCUMENTS_VIEW: {
    firebase: {
      eventName: 'web_documents_view'
    }
  },
  WEB_DOCUMENTS_COPY_PASSWORD_CLICK: {
    firebase: {
      eventName: 'web_documents_copy_password_click'
    }
  },
  WEB_DOCUMENTS_GENERATE_PASSWORD_CLICK: {
    firebase: {
      eventName: 'web_documents_generate_password_click'
    }
  },
  WEB_DOCUMENTS_RESTORE_ACCESS_CLICK: {
    firebase: {
      eventName: 'web_documents_restore_access_click'
    }
  },
  WEB_DIALOG_DOCUMENTS_DELETE_WALLET_CLICK: {
    firebase: {
      eventName: 'web_dialog_documents_delete_wallet_click'
    }
  },
  WEB_MENU_DOCUMENTS_DELETE_WALLET_CLICK: {
    firebase: {
      eventName: 'web_menu_documents_delete_wallet_click'
    }
  },
  WEB_DOCUMENTS_PREVIEW_VIEW: {
    firebase: {
      eventName: 'web_documents_preview_view'
    }
  },
  WEB_DOCUMENTS_PREVIEW_SWIPE: {
    firebase: {
      eventName: 'web_documents_preview_swipe'
    }
  },
  WEB_ALBUM_PLACES_CLICK: {
    firebase: {
      eventName: 'web_album_places_click'
    }
  },
  WEB_ALBUM_PLACES_SEE_ALL_CLICK: {
    firebase: {
      eventName: 'web_album_places_see_all_click'
    }
  },
  WEB_FC_ALBUM_PLACES_CLICK: {
    firebase: {
      eventName: 'web_fc_album_places_click'
    }
  },
  WEB_FC_ALBUM_PLACES_SEE_ALL_CLICK: {
    firebase: {
      eventName: 'web_fc_album_places_see_all_click'
    }
  },
  WEB_ALBUM_SEASON_CLICK: {
    firebase: {
      eventName: 'web_album_season_click'
    }
  },
  WEB_ALBUM_SEASON_SEE_ALL_CLICK: {
    firebase: {
      eventName: 'web_album_season_see_all_click'
    }
  },
  WEB_PERSON_CREATE_CLICK: {
    firebase: {
      eventName: 'web_person_create_click'
    }
  },
  WEB_PERSON_ADD_COLOR_CLICK: {
    firebase: {
      eventName: 'web_person_add_color_click'
    }
  },
  WEB_PERSON_EDIT_CLICK: {
    firebase: {
      eventName: 'web_person_edit_click'
    }
  },
  WEB_PERSON_EDIT_ORDER_CLICK: {
    firebase: {
      eventName: 'web_person_edit_order_click'
    }
  },
  WEB_PERSON_DELETE_CLICK: {
    firebase: {
      eventName: 'web_person_delete_click'
    }
  },
  WEB_PHOTO_SEARCH_SIMILAR_CLICK: {
    firebase: {
      eventName: 'web_photo_search_similar_click'
    }
  },
  WEB_SIMILAR_PHOTOS_DOWNLOAD_CLICK: {
    firebase: {
      eventName: 'web_similar_photos_download_click'
    }
  },
  WEB_SIMILAR_PHOTOS_DELETE_CLICK: {
    firebase: {
      eventName: 'web_similar_photos_delete_click'
    }
  },
  WEB_SIMILAR_PHOTOS_ALBUM_ADD_CLICK: {
    firebase: {
      eventName: 'web_similar_photos_album_add_click'
    }
  },
  WEB_FILES_SEARCH_VIEW: {
    firebase: {
      eventName: 'web_files_search_view'
    }
  },
  WEB_FILES_SEARCH_ENTER: {
    firebase: {
      eventName: 'web_files_search_enter'
    }
  },
  WEB_FILES_RECENTLY_OPENED_VIEW: {
    firebase: {
      eventName: 'web_files_recently_opened_view'
    }
  },
  WEB_PHOTOS_LIVEPHOTO_PLAY: {
    firebase: {
      eventName: 'web_photos_livephoto_play'
    }
  },
  WEB_PHOTOS_LIVEPHOTO_PAUSE: {
    firebase: {
      eventName: 'web_photos_livephoto_pause'
    }
  },
  WEB_THEME_COLOR_SWITCH: {
    firebase: {
      eventName: 'web_theme_color_switch'
    }
  },
  WEB_THEME_AUTOMATIC_SWITCH: {
    firebase: {
      eventName: 'web_theme_automatic_switch'
    }
  },
  WEB_FOLDER_CREATE_LINK: {
    firebase: {
      eventName: 'web_folder_createLink',
    }
  },
  WEB_FOLDER_EDIT_LINK: {
    firebase: {
      eventName: 'web_folder_editLink',
    }
  },
  WEB_FILES_CREATE_LINK: {
    firebase: {
      eventName: 'web_files_createLink',
    }
  },
  WEB_FILES_EDIT_LINK: {
    firebase: {
      eventName: 'web_files_editLink',
    }
  },
  WEB_PUBLIC_DELETE_LINK: {
    firebase: {
      eventName: 'web_public_deleteLink',
    }
  },
  WEB_PREVIEW_DOWNLOAD: {
    firebase: {
      eventName: 'web_preview_download',
    }
  },
  WEB_PREVIEW_PUBLIC_LINK: {
    firebase: {
      eventName: 'web_preview_public_link',
    }
  },
  WEB_PREVIEW_ADD_TO_ALBUM: {
    firebase: {
      eventName: 'web_preview_add_to_album',
    }
  },
  WEB_PREVIEW_ADD_TO_FAMILYCLOUD: {
    firebase: {
      eventName: 'web_preview_add_to_FamilyCloud',
    }
  },
  WEB_PREVIEW_INFO: {
    firebase: {
      eventName: 'web_preview_info',
    }
  },
  WEB_PREVIEW_VERSIONS: {
    firebase: {
      eventName: 'web_preview_versions',
    }
  },
  WEB_PREVIEW_DELETE: {
    firebase: {
      eventName: 'web_preview_delete',
    }
  },
  WEB_PREVIEW_CLOSE: {
    firebase: {
      eventName: 'web_preview_close',
    }
  },
  WEB_PHOTO_CREATE_LINK: {
    firebase: {
      eventName: 'web_photo_createLink',
    }
  },
  WEB_PHOTO_EDIT_LINK: {
    firebase: {
      eventName: 'web_photo_editLink',
    }
  },
  WEB_ALBUM_CREATE_LINK: {
    firebase: {
      eventName: 'web_album_createLink',
    }
  },
  WEB_ALBUM_EDIT_LINK: {
    firebase: {
      eventName: 'web_album_editLink',
    }
  },
  WEB_PHOTOS_SEARCH_ENTER: {
    firebase: {
      eventName: 'web_photos_search_enter'
    }
  },
  WEB_PHOTOS_SEARCH_ALBUM_PLACES_CLICK: {
    firebase: {
      eventName: 'web_photos_search_album_places_click'
    }
  },
  WEB_PHOTOS_SEARCH_ALBUM_PLACES_SEE_ALL_CLICK: {
    firebase: {
      eventName: 'web_photos_search_album_places_see_all_click'
    }
  },
  WEB_PHOTOS_SEARCH_ALBUMS_CLICK: {
    firebase: {
      eventName: 'web_photos_search_albums_click'
    }
  },
  WEB_PHOTOS_SEARCH_ALBUM_DATES_CLICK: {
    firebase: {
      eventName: 'web_photos_search_album_dates_click'
    }
  },
  WEB_PHOTO_FILTERS_DAY_CLICK: {
    firebase: {
      eventName: 'web_photo_filters_day_click'
    }
  },
  WEB_PHOTO_FILTERS_MONTH_CLICK: {
    firebase: {
      eventName: 'web_photo_filters_month_click'
    }
  },
  WEB_PHOTO_FILTERS_YEAR_CLICK: {
    firebase: {
      eventName: 'web_photo_filters_year_click'
    }
  },
  WEB_FC_PHOTO_FILTERS_DAY_CLICK: {
    firebase: {
      eventName: 'web_fc_photo_filters_day_click'
    }
  },
  WEB_FC_PHOTO_FILTERS_MONTH_CLICK: {
    firebase: {
      eventName: 'web_fc_photo_filters_month_click'
    }
  },
  WEB_FC_PHOTO_FILTERS_YEAR_CLICK: {
    firebase: {
      eventName: 'web_fc_photo_filters_year_click'
    }
  },
  WEB_PHOTOS_INSIDE_ADD_TO_ALBUM: {
    firebase: {
      eventName: 'web_photos_inside_add_to_album',
    }
  },

  WEB_PHOTOS_INSIDE_UPLOAD_TO_ALBUM: {
    firebase: {
      eventName: 'web_photos_inside_upload_to_album',
    }
  },
  WEB_PHOTOS_INSIDE_ADD_FROM_DEVICE: {
    firebase: {
      eventName: 'web_photos_inside_add_from_device',
    }
  },
  WEB_PHOTOS_ALBUM_CREATE: {
    firebase: {
      eventName: 'web_photos_album_create',
    }
  },
  WEB_PHOTOS_ALBUM_INSIDE_RENAME: {
    firebase: {
      eventName: 'web_photos_album_inside_rename',
    }
  },
  WEB_PHOTOS_ALBUM_OUTSIDE_RENAME: {
    firebase: {
      eventName: 'web_photos_album_outside_rename',
    }
  },
  WEB_PHOTOS_INSIDE_ALBUM_DELETE: {
    firebase: {
      eventName: 'web_photos_inside_album_delete',
    }
  },
  WEB_PHOTOS_OUTSIDE_ALBUM_DELETE: {
    firebase: {
      eventName: 'web_photos_outside_album_delete',
    }
  },
  WEB_PHOTOS_FACES_ADD_NAME: {
    firebase: {
      eventName: 'web_photos_faces_add_name',
    }
  },
  WEB_PHOTOS_FACES_SEE_ALL_CLICK: {
    firebase: {
      eventName: 'web_photos_faces_see_all_click',
    }
  },
  WEB_PHOTOS_FACES_VIEW: {
    firebase: {
      eventName: 'web_photos_faces_view',
    }
  },
  WEB_FC_CREATE_INVITE_MEMBERS: {
    firebase: {
      eventName: 'web_fc_create_invite_members',
    }
  },
  WEB_FC_CREATE_UPLOAD: {
    firebase: {
      eventName: 'web_fc_create_upload',
    }
  },
  WEB_FC_CREATE_SHOW_QR: {
    firebase: {
      eventName: 'web_fc_create_show_QR',
    }
  },
  WEB_FC_CREATE_CANCEL: {
    firebase: {
      eventName: 'web_fc_create_cancel',
    }
  },
  WEB_FC_CREATE_DONE: {
    firebase: {
      eventName: 'web_fc_create_done',
    }
  },
  WEB_FC_CREATE_ADD_FROM_DEVICE: {
    firebase: {
      eventName: 'web_fc_create_add_from_device',
    }
  },
  WEB_FC_FAVORITES_CLICK: {
    firebase: {
      eventName: 'web_fc_favorites_click',
    }
  },
  WEB_FC_INVITE: {
    firebase: {
      eventName: 'web_fc_invite',
    }
  },
  WEB_FC_ALBUM_CREATE: {
    firebase: {
      eventName: 'web_fc_album_create',
    }
  },
  WEB_FC_INSIDE_ADD_TO_ALBUM_PERSONAL: {
    firebase: {
      eventName: 'web_fc_inside_add_to_album_personal',
    }
  },
  WEB_FC_INSIDE_ADD_TO_ALBUM_FAMILY: {
    firebase: {
      eventName: 'web_fс_inside_add_to_album_family',
    }
  },
  WEB_FC_INSIDE_ADD_TO_ALBUM_DEVICE: {
    firebase: {
      eventName: 'web_fc_inside_add_to_album_device',
    }
  },
  WEB_FC_INSIDE_ALBUM_RENAME: {
    firebase: {
      eventName: 'web_fc_inside_album_rename',
    }
  },
  WEB_FC_OUTSIDE_ALBUM_RENAME: {
    firebase: {
      eventName: 'web_fc_outside_album_rename',
    }
  },
  WEB_FC_INSIDE_ALBUM_DELETE: {
    firebase: {
      eventName: 'web_fc_inside_album_delete',
    }
  },
  WEB_FC_OUTSIDE_ALBUM_DELETE: {
    firebase: {
      eventName: 'web_fc_outside_album_delete',
    }
  },
  WEB_FC_FACES_ADD_NAME: {
    firebase: {
      eventName: 'web_fc_faces_add_name',
    }
  },
  WEB_PHOTOS_OUTSIDE_ADD_TO_ALBUM: {
    firebase: {
      eventName: 'web_photos_outside_add_to_album'
    }
  },
  WEB_FC_OUTSIDE_ADD_TO_ALBUM: {
    firebase: {
      eventName: 'web_fc_outside_add_to_album'
    }
  },
  WEB_DESK_DOWNLOAD_WIN_CLICK: {
    firebase: {
      eventName: 'web_desk_download_win_click'
    }
  },
  WEB_DESK_DOWNLOAD_MAC_CLICK: {
    firebase: {
      eventName: 'web_desk_download_mac_click'
    }
  }
}
