import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'

import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { useUploadingFinishDetection } from 'features/common/files-uploading/useUploadingFinishDetection'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { useTranslation } from 'react-i18next'
import { uploadFavoriteItemsThunk, uploadTimelineItemsThunk } from 'features/common/files-uploading/filesUploadingSlice'
import {
  PageSpiner,
  PhotoCard,
  PhotosGroupTitle,
  STYLED_VARIABLES
} from '@cloudike/web_ui_components'
import { DropzoneArea } from 'features/common/dropzone/DropzoneArea'
import classNames from 'classnames'
import { getIsDropzoneAreaVisibleSelector } from 'features/common/dropzone/selectors'
import { dropzoneActions, removeUploadHandlerThunk, setUploadHandlerThunk } from 'features/common/dropzone/dropzoneSlice'
import { analyticsService } from 'features/common/analytics/analyticsService'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'
import { useNavigate } from "react-router-dom"
import { useVirtualizer } from "@tanstack/react-virtual"
import { getTimelineCardWidth } from 'utils/timelineDigests'
import { useMobileDetection } from 'features/common/hooks'
import { getIsMainContainerWithFullWidth } from 'features/common/selectors'

import { openPhotoPreviewThunk, photoPreviewActions, PREVIEW_TYPES } from '../photo-preview/photoPreviewSlice'
import { usePreviewItemsUpdate } from '../photo-preview/usePreviewItemsUpdate'
import { MemoriesComponent } from "../MemoriesComponent"
import { photoPreviewFlashbackActions } from "../photo-preview-flashback/photoPreviewFlashbackSlice"
import { Tab } from "../../../ui/Tabs"
import { useAppDispatch, useAppSelector } from "../../../store"
import { FilterSelect } from "../../../ui/FilterSelect"
import { appActions } from "../../../store/app"
import { DATES_FORMATS } from "../../../constants/datesFormats"

import {
  getDateFormatSelector,
  getFlashbackAlbumsSelector,
  getSortFormatSelector,
  getTimelineFilteredItemsSelector,
  getTimelineFilterSelector,
  getTimelineItemsByDateSelector,
  getTimelineSelectedItemsCountSelector,
  getTimelineSelectedItemsIdsSelector,
  getTimelineTotalItemsCountSelector
} from './selectors'
import {
  loadJustUploadedTimelineItemsThunk,
  loadMoreTimelineItemsThunk,
  SORT_FORMATS,
  TIMELINE_FILTERS,
  timelineActions
} from './timelineSlice'
import { EmptyTimelinePlaceholder } from './EmptyTimelinePlaceholder'
import { TimelineTabs } from "./TimelineTabs"
import { DigestsScrollbar } from "./DigestsScrollbar"
import { DigestsScrollbarMobile } from './DigestsScrollbarMobile'


interface TimelineProps {
    type: SDK_TYPES,
    className?: string,
    withDisabledPreview?: boolean,
    withDisabledPhotoCard?: boolean,
    withMemories?: boolean,
    withFilters?: boolean,
    withControls?: boolean,
    isTimelinePage?: boolean,
    isAddToFavoritesPage?: boolean,
    cardsPerRow: number,
    withDigests?: boolean,
    isAddingPhotoToAlbum?: boolean
}

export const Timeline: React.FC<TimelineProps> = ({
  className = '',
  type,
  withDisabledPreview,
  withDisabledPhotoCard,
  withMemories,
  withFilters,
  withControls = true,
  cardsPerRow,
  withDigests = false,
  isAddingPhotoToAlbum = false
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const dateFormat = getDateFormatSelector()
  const itemsByDateSelector = useMemo(() => getTimelineItemsByDateSelector(dateFormat), [dateFormat])
  const itemsByDate = useAppSelector(itemsByDateSelector)
  const filter = getTimelineFilterSelector()
  const items = getTimelineFilteredItemsSelector()
  const selectedItemsIds = getTimelineSelectedItemsIdsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const totalItemsCount = getTimelineTotalItemsCountSelector()
  const isDropzoneAreaVisible = getIsDropzoneAreaVisibleSelector()
  const smartAlbums = getFlashbackAlbumsSelector()
  const sortFormat = getSortFormatSelector()
  const isMainContainerWithFullWidthOnMobile = getIsMainContainerWithFullWidth()

  const tabsRef = useRef(null)
  const scrollRef = useRef(null)
  const elementsScrollableContainerRef = useRef<HTMLDivElement>(null)
  const isMobile = useMobileDetection()
  const isLessThan1024 = useMobileDetection(1024)

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()

  const filterOptions = [
    { label: t('a_photos_searchAllItems'), value: TIMELINE_FILTERS.all, icon: 'picture_polaroid_landscape' },
    { label: t('a_photos_searchPhotos'), value: TIMELINE_FILTERS.photos, icon: 'image_2' },
    { label: t('a_photos_searchVideos'), value: TIMELINE_FILTERS.videos, icon: 'video_2' },
    { label: t('a_nav_favorites'), value: TIMELINE_FILTERS.favorites, icon: 'favorite_border' }
  ]

  const sortOptions = [
    {
      label: t('a_common_sortDay'),
      value: DATES_FORMATS.timelineDay,
      icon: 'sort_day'
    },
    {
      label: t('a_common_sortMonth'),
      value: DATES_FORMATS.timelineMonth,
      icon: 'sort_month'
    },
    {
      label: t('a_common_sortYear'),
      value: DATES_FORMATS.timelineYear,
      icon: 'sort_year'
    },
  ]

  const heightBeforeScroll = (withMemories && smartAlbums?.length ? 170 : 0)
  const heightBeforeScrollMobile = (withMemories && smartAlbums?.length ? 170 : 0) + (withControls ? 60 : 0)

  const loadMore = () => {
    dispatch(loadMoreTimelineItemsThunk())
  }

  useEffect(() => {
    if (items.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, items.length])

  usePreviewItemsUpdate(items, totalItemsCount)

  useUploadingFinishDetection(() => dispatch(loadJustUploadedTimelineItemsThunk()))

  const handleItemSelection = useCallback((id) => {
    dispatch(timelineActions.selectItem(id))
  }, [])

  const handleWholeGroupSelection = useCallback((items) => {
    let ids = items.map(item => item.id)

    if (withDisabledPhotoCard) {
      const arr = items.filter(item => !item.favorite)
      ids = arr.map(item => item.id)
      items = [...arr]
    }

    if (items.some(item => !selectedItemsIds.includes(item.id))) {
      dispatch(timelineActions.selectAllItemsInGroup(ids))
    } else {
      dispatch(timelineActions.unselectAllItemsInGroup(ids))
    }
  }, [itemsByDate, selectedItemsIds])

  const handleOpenPreview = useCallback((id) => {
    analyticsService.push(ANALYTICS_EVENTS.WEB_PHOTO_VIDEO_CLICK)

    dispatch(openPhotoPreviewThunk({
      items,
      totalItemsCount: withDigests ? items.length : totalItemsCount,
      currentItemId: id,
      sdkType: type,
      type: PREVIEW_TYPES.TIMELINE,
    }))
  }, [items, totalItemsCount])

  const handleUploadFiles = (acceptedFiles: FileList) => {
    if (acceptedFiles.length) {
      if (filter === TIMELINE_FILTERS.favorites) {
        dispatch(uploadFavoriteItemsThunk({ files: acceptedFiles, type }))
      } else {
        dispatch(uploadTimelineItemsThunk({ files: acceptedFiles, type }))
      }
    }

    if (type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_UPLOAD_CLICK)
    }

    if (type === SDK_TYPES.FAMILY) {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_PHOTO_UPLOAD_CLICK)
    }
  }

  const handleAddFavorites = () => {
    if (type === SDK_TYPES.DEFAULT) {
      navigate('/photos/favorites/add')
    } else {
      navigate('/family/photos/favorites/add')
    }

    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
  }

  const handleChangeTab = (tab: Tab) => {
    dispatch(timelineActions.setDateFormat(tab.value))
    dispatch(timelineActions.setSortFormat(tab.sort))

    virtualizer.scrollToOffset(0, { behavior: 'auto' })

    switch (tab.value) {
    case DATES_FORMATS.timelineYear:
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_YEAR_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_YEAR_CLICK)
      return
    case DATES_FORMATS.timelineMonth:
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_MONTH_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_MONTH_CLICK)
      return
    default:
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_DAY_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_DAY_CLICK)
    }
  }

  const handleFilterChange = (option) => {
    dispatch(timelineActions.unselectAll())
    dispatch(timelineActions.setFilter(option.value))
    // dispatch(timelineActions.setLoadingStatus(LOADING_STATUSES.LOADING))

    if (type === SDK_TYPES.FAMILY && option.value === TIMELINE_FILTERS.favorites) {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_FAVORITES_CLICK)
    }
  }

  const handleSortChange = (option) => {
    dispatch(timelineActions.setDateFormat(option.value))

    switch (option.value) {
    case DATES_FORMATS.timelineYear:
      dispatch(timelineActions.setSortFormat(SORT_FORMATS.sortFormat.year))
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_YEAR_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_YEAR_CLICK)
      return
    case DATES_FORMATS.timelineMonth:
      dispatch(timelineActions.setSortFormat(SORT_FORMATS.sortFormat.month))
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_MONTH_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_MONTH_CLICK)
      return
    default:
      dispatch(timelineActions.setSortFormat(SORT_FORMATS.sortFormat.day))
      analytics.push(type === SDK_TYPES.FAMILY ? ANALYTICS_EVENTS.WEB_FC_PHOTO_FILTERS_DAY_CLICK : ANALYTICS_EVENTS.WEB_PHOTO_FILTERS_DAY_CLICK)
    }
  }

  useEffect(() => {
    dispatch(setUploadHandlerThunk({ callback: handleUploadFiles }))
    dispatch(dropzoneActions.updateDropzoneOptions({
      disabled: false
    }))

    return () => {
      dispatch(removeUploadHandlerThunk())
      dispatch(dropzoneActions.updateDropzoneOptions({
        disabled: true
      }))
      dispatch(photoPreviewActions.resetState())
      dispatch(photoPreviewFlashbackActions.resetState())
    }
  }, [])

  const memoizedItemsByDate = useMemo(() => itemsByDate, [
    itemsByDate
  ])

  const itemsByDateSplitedToRows = useMemo(() => {
    const result = []

    memoizedItemsByDate.forEach(item => {
      result.push({
        type: 'title',
        date: item.date,
        id: item.date,
        items: item.items
      })

      const rowsCount = Math.ceil(item?.items?.length / (cardsPerRow || 1))

      for(let i = 0; i < rowsCount; i++) {
        result.push({
          type: 'row',
          id: `${item.date}-${i}`,
          items: item.items.slice(i * cardsPerRow, (i + 1) * cardsPerRow)
        })
      }
    })

    return result
  }, [memoizedItemsByDate, cardsPerRow])

  const virtualizer = useVirtualizer({
    count: itemsByDateSplitedToRows.length,
    getScrollElement: () => elementsScrollableContainerRef.current,
    estimateSize: (index) => {
      if(itemsByDateSplitedToRows[index].type === 'title') {
        return 60
      }

      const isLastItem = index === itemsByDateSplitedToRows.length - 1
      const baseHeight = getTimelineCardWidth(sortFormat)
      return isLastItem ? baseHeight + 20 : baseHeight
    },
    overscan: 2,
    paddingEnd: isMobile ? 60 : 0,
  })

  const virtualItems = virtualizer.getVirtualItems()

  const renderPhotoCard = useCallback((item) => (
    <PhotoCard
      key={item.id}
      id={item.id}
      imgUrl={item._links?.image_middle?.href}
      isSelected={selectedItemsIds.includes(item.id)}
      onSelect={handleItemSelection}
      onClick={withDisabledPreview ? handleItemSelection : handleOpenPreview}
      isCheckboxVisibleWithoutHover={selectedItemsCount > 0}
      type={item.type}
      isFavorite={item.favorite}
      isDisabled={withDisabledPhotoCard && item.favorite}
      isPreloaderVisible={item.type === 'placeholder'}
    />
  ), [selectedItemsIds, handleItemSelection, handleOpenPreview, selectedItemsCount, withDisabledPreview, withDisabledPhotoCard])

  const timelineList = useMemo(() => virtualItems.map((virtualRow) => {
    const row = itemsByDateSplitedToRows[virtualRow.index]

    if (!row) {
      return null
    }

    if (row.type === 'title') {
      const items = row.items
      const isTitleChecked = withDisabledPhotoCard
        ? !items.filter(item => !item.favorite).some(item => !selectedItemsIds.includes(item.id))
        : !items.some(item => !selectedItemsIds.includes(item.id))
      const isTitleDisabled = items.some(item => !item.favorite)

      return (
        <PhotosGroupTitleBox
          key={virtualRow.key}
          data-index={virtualRow.index}
          ref={virtualizer.measureElement}
          title={row.date}
          checked={isTitleChecked}
          onClick={() => handleWholeGroupSelection(items)}
          checkIconVisibleByDefault={selectedItemsCount > 0}
          isDisabled={withDisabledPhotoCard ? !isTitleDisabled : withDisabledPhotoCard}
          className={classNames({ 'with-padding-on-mobile': isMainContainerWithFullWidthOnMobile })}
        />
      )
    }

    if (row.type === 'row') {
      return (
        <SGridRow key={virtualRow.key}
          data-index={virtualRow.index}
          ref={virtualizer.measureElement}
          cardsPerRow={cardsPerRow}
          className={sortFormat === SORT_FORMATS.sortFormat.month ? 'sorted-by-month' : sortFormat === SORT_FORMATS.sortFormat.year ? 'sorted-by-year' : ''}
        >
          <GridRowContent
            items={row.items}
            renderPhotoCard={renderPhotoCard}
          />
        </SGridRow>
      )
    }

    return null
  }), [virtualItems, itemsByDateSplitedToRows, selectedItemsIds, handleWholeGroupSelection, renderPhotoCard, handleOpenPreview])

  if (!items.length) {
    return (
      <TimelineBox>
        {withControls && (
          <SControls>
            <STimelineTabs activeTab={dateFormat}
              onChangeTab={handleChangeTab}
            />

            <SFilterSelect options={sortOptions}
              value={sortOptions.find(option => option.value === dateFormat)}
              onSelect={handleSortChange}
            />

            {withFilters && (
              <FilterSelect options={filterOptions}
                value={filterOptions.find(option => option.value === filter)}
                onSelect={handleFilterChange}
              />
            )}
          </SControls>
        )
        }

        <EmptyTimelinePlaceholder
          texts={{
            title: filter === TIMELINE_FILTERS.favorites ? t('l_favorites_noTitle') : t('l_photos_emptyPageTitle'),
            description: filter === TIMELINE_FILTERS.favorites ? t('l_favorites_noMessage') : t('l_photos_emptyPageMessage') ,
            uploadBtn: filter === TIMELINE_FILTERS.favorites ? t('a_common_addToFavorites') : t('a_common_uploadPhotos')
          }}
          onUploadFiles={filter === TIMELINE_FILTERS.favorites ? handleAddFavorites : handleUploadFiles}
          withoutUploading={filter === TIMELINE_FILTERS.favorites}
        />

        <DropzoneArea
          visible={isDropzoneAreaVisible}
        />
      </TimelineBox>
    )
  }

  const isScrollbarVisible = virtualizer.getTotalSize() - virtualizer.options.getScrollElement()?.clientHeight > 50

  return (
    <STimelineScroll
      ref={scrollRef}
    >
      <TimelineBox
        className={classNames(className, { 'with-overflow': isDropzoneAreaVisible })}
      >
        <STimelineItemsBox
          ref={elementsScrollableContainerRef}
          className={classNames({ 'without-native-scrollbar': withDigests, 'adding-photo-to-album': isAddingPhotoToAlbum })}
        >
          <TimelineItemsInnerBox>
            {withMemories && (
              <MemoriesComponent
                smartAlbums={smartAlbums}
                className="memories-component"
              />
            )}

            {withControls && (
              <SControls
                className={classNames('timeline-controls', 'top-to-family', {
                  'with-padding-on-mobile': isMainContainerWithFullWidthOnMobile
                })}
                ref={tabsRef}
              >
                <STimelineTabs activeTab={dateFormat}
                  onChangeTab={handleChangeTab}
                />

                <SFilterSelect options={sortOptions}
                  value={sortOptions.find(option => option.value === dateFormat)}
                  onSelect={handleSortChange}
                />

                {withFilters && (
                  <FilterSelect options={filterOptions}
                    value={filterOptions.find(option => option.value === filter)}
                    onSelect={handleFilterChange}
                  />
                )}
              </SControls>
            )}

            <div style={{
              height: virtualizer.getTotalSize() + 'px',
              position: 'relative',
            }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
                }}
              >
                {timelineList}

                {!withDigests && items.length < totalItemsCount && (
                  <div ref={loaderBoxRef}>
                    <StyledPageSpinner />
                  </div>
                )}
              </div>
            </div>
          </TimelineItemsInnerBox>
        </STimelineItemsBox>

        {withDigests && !isLessThan1024 && isScrollbarVisible && (
          <SDigestsScrollbar
            virtualizer={virtualizer}
            heightBeforeScroll={heightBeforeScroll}
          />
        )}

        {withDigests && isLessThan1024 && isScrollbarVisible && (
          <SDigestsScrollbarMobile
            virtualizer={virtualizer}
            heightBeforeScroll={heightBeforeScrollMobile}
          />
        )}
      </TimelineBox>

      <DropzoneArea
        visible={isDropzoneAreaVisible}
      />
    </STimelineScroll>
  )
}

interface GridRowContentProps {
  items: any[]
  renderPhotoCard: (item: any) => React.ReactNode
}

const GridRowContent = React.memo(({ items, renderPhotoCard }: GridRowContentProps) => {
  const [inView, setInView] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInView(true)
    }, 300)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      {!inView && Array.from({ length: items.length }).map((_, index) => (
        <SGridRowPhotoCardPlaceholder key={index} />
      ))}

      {inView && items.map(renderPhotoCard)}
    </>
  )
})

const STimelineScroll = styled.div`
  margin-right: -40px;
  padding-right: 5px;
  position: relative;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and  (min-width: 1024px) {
    height: calc(100% - 62px);
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    margin-right: unset;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    height: calc(100vh - 80px);
    padding-right: 0;
  }
`

const PhotosGroupTitleBox = styled(PhotosGroupTitle)`
  text-transform: capitalize;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 0!important;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    &.with-padding-on-mobile {
      padding-left: 16px!important;
    }
  }
`

const TimelineBox = styled.div`
  width: 100%;
  position: relative;

  &.with-overflow {
    overflow: hidden;
  }
  
  &.no-width {
    width: unset !important;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    width: 100vw;
  }
`

const STimelineItemsBox = styled.div`
  width: calc(100% - 36px);
  height: calc(100vh - 83px);
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 20px;
  padding-right: 8px;
  
  &.with-overflow-hidden {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;


    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &.without-native-scrollbar {
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    width: calc(100% - 20px);
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    height: calc(100vh - 60px);
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    width: 100vw;
  }
  
  &.adding-photo-to-album{
    width: unset;
  }
`

const TimelineItemsInnerBox = styled.div`
  width: 100%;
  position: relative;
`

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`

const SControls = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  margin-bottom: 0;
  padding-bottom: 4px;
  height: 60px;
  width: 100%;
  z-index: 10;
  background: var(--background-primary);
  align-items: center;
  
  
  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    width: calc(100% + 32px);
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    position: static;
    padding: 0 16px;

    &.with-padding-on-mobile {
      padding-left:  16px;
      width: 100%;
    }
  }
`

const SFilterSelect = styled(FilterSelect)`
  margin-right: 8px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

const STimelineTabs = styled(TimelineTabs)`
  display: none;
    
  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: flex;
  }
`

const SGridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.cardsPerRow}, 172px);
  gap: 8px;
  margin-bottom: 8px;

  &.sorted-by-month {
    grid-template-columns: repeat(${props => props.cardsPerRow}, 120px);
  }

  &.sorted-by-year {
    grid-template-columns: repeat(${props => props.cardsPerRow}, 92px);
  }
/* 
  @media (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  } */

  @media (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @media (max-width: 320px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }
`

const SDigestsScrollbar = styled(DigestsScrollbar)`
  &.hidden {
    opacity: 0!important;
  }
`

const SDigestsScrollbarMobile = styled(DigestsScrollbarMobile)`

`

const SGridRowPhotoCardPlaceholder = styled.div`
  padding-bottom: 100%;
  background: var(--background-tertiary);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.35) 0%, rgba(255, 255, 255, 0) 50.57%);
  }
`
