import { useEffect, useRef, useState } from 'react'

import { SpriteIcon, STYLED_VARIABLES, useOnClickOutside } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from "store"
import {
  albumActions,
  copyAlbumItemsToFamilyCloudThunk,
  copyAlbumItemsToPersonalCloudThunk,
  createAndShareAlbumThunk,
  downloadAlbumItemsThunk,
  removeAlbumItemsThunk
} from 'features/albums/album/albumSlice'
import { useTranslation } from 'react-i18next'
import { getUserDataSelector, getUserSettingsByPathSelector } from 'features/user/selectors'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { DialogModal } from 'ui/DialogModal'
import { toMb } from 'utils/utils'
import moment from 'moment'
import classNames from 'classnames'
import {
  addItemsToPersonalCloudThunk,
  downloadSharedAlbumItemThunk,
  removeSharedAlbumSelectedItemsThunk
} from 'features/public-link/publicLinkSlice'
import { getPublicLinkPermissionSelector } from 'features/public-link/selectors'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'
import { deleteDocumentItems, downloadSelectedDocumentItems } from 'features/documents/documentsSlice'
import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'
import { Tooltip } from "react-tooltip"
import _ from "lodash"
import { deleteTrashBinItemsThunk, restoreTrashBinSelectedItemsThunk } from 'features/trash-bin/trashBinSlice'

import {
  copyTimelineItemsToFamilyThunk,
  copyTimelineItemsToPersonalCloudThunk,
  downloadTimelineItemsThunk,
  removeSelectedItemsThunk,
  TIMELINE_FILTERS
} from '../timeline/timelineSlice'
import { getIsUserAuthorizedSelector } from "../../user/userSlice"
import { addSelectedToFavoritesItemsThunk, removeSelectedFromFavoritesItemsThunk } from "../../favorites/favoritesSlice"
import { USER_SETTINGS_PATHS } from "../../user/constants"
import {
  getPhotosDuplicateThunk,
  photoPreviewDuplicateActions
} from "../photo-preview-duplicate/photoPreviewDuplicateSlice"
import {
  getPhotoPreviewDuplicateItemsSelector,
  getPhotoPreviewDuplicateModeSelector
} from "../photo-preview-duplicate/selectors"
import { InfoModalPreview } from "../../common/info-modal/InfoModalPreview"
import { useMobileDetection } from "../../common/hooks"
import { getTimelineFilterSelector } from "../timeline/selectors"
import { appActions } from "../../../store/app"
import { fetchAlbumsThunk } from "../../albums/albums-list/albumsSlice"
import { getPublicLinkAndCopyForPhotoThunk } from "../../sharing/sharingSlice"
import { photoPreviewTransformerActions } from '../photo-transformer-preview/photoPreviewTransformerSlice'

import { photoPreviewActions, PREVIEW_TYPES } from './photoPreviewSlice'
import { DropMenuPreview } from "./DropMenuPreview"
import {
  getAreFacesVisibleSelector,
  getCurrentFacesDate,
  getCurrentPhotoPreviewItemIndexSelector,
  getCurrentPhotoPreviewSdkTypeSelector,
  getCurrentPhotoPreviewTypeSelector,
  getIsLivePhotoCanPlaySelector,
  getIsLivePhotoPlaying,
  getPhotoPreviewItemsSelector
} from './selectors'

export const PhotoPreviewToolbar = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isMobile = useMobileDetection(767)
  const isTablet = useMobileDetection(1280)

  const isEnabledPhotoDuplicates = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.PHOTOS_DUPLICATES).enabled

  const items = getPhotoPreviewItemsSelector()
  const currentIndex = getCurrentPhotoPreviewItemIndexSelector()
  const sdkType = getCurrentPhotoPreviewSdkTypeSelector()
  const previewType = getCurrentPhotoPreviewTypeSelector()
  const timelineFilter = getTimelineFilterSelector()
  const userData = getUserDataSelector()
  const isAuthorized = getIsUserAuthorizedSelector()
  const isEnabledPreviewDuplicateMode = getPhotoPreviewDuplicateModeSelector()
  const areFacesVisible = getAreFacesVisibleSelector()
  const facesData = getCurrentFacesDate()
  const isEnableTransformerPhoto = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.TRANSFORMER)?.enabled

  const sharedPermission = getPublicLinkPermissionSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [isShowDropMenu, toggleShowDropMenu] = useState(false)
  const [isOpenedInfoModal, setOpenedInfoModal] = useState(false)
  const [restoreConfirmationModalOpened, toggleRestoreConfirmationModal] = useState(false)
  const [trashBinRemovingConfirmationModalOpened, toggleTrashBinRemovingConfirmationModal] = useState(false)
  const ref = useRef(null)

  useOnClickOutside(ref, () => {
    if (isShowDropMenu) toggleShowDropMenu(!isShowDropMenu)
  })

  const [currentItem, setCurrentItem] = useState(items[currentIndex] as IItemSchema)

  useEffect(() => {
    if (isEnabledPreviewDuplicateMode && !!duplicateItems.length) return

    setCurrentItem(items[currentIndex] as IItemSchema)
  }, [currentIndex, items])

  const duplicateItems = getPhotoPreviewDuplicateItemsSelector()
  const selectDuplicateItem = duplicateItems.find(item => item.isSelect)

  useEffect(() => {
    if (!!selectDuplicateItem) setCurrentItem(selectDuplicateItem as IItemSchema)
  }, [selectDuplicateItem])

  const isLivePhotoCanPlay = getIsLivePhotoCanPlaySelector()
  const isLivePhotoPlaying = getIsLivePhotoPlaying()

  const handleClose = () => {
    dispatch(photoPreviewActions.resetState())
    dispatch(photoPreviewDuplicateActions.resetState())

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_CLOSE)
  }

  const handleTogglePlay = () => {
    dispatch(photoPreviewActions.toggleLivePhotoPlaying())
  }

  const handleShare = () => {
    dispatch(createAndShareAlbumThunk({
      items: [currentItem],
      type: sdkType === SDK_TYPES.DEFAULT ? SDK_TYPES.DEFAULT : SDK_TYPES.FAMILY
    }))

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_PUBLIC_LINK)
  }

  const handleCopyToFamily = _.debounce(() => {
    if (previewType === PREVIEW_TYPES.ALBUM || previewType === PREVIEW_TYPES.PLACE_ALBUM) {
      dispatch(copyAlbumItemsToFamilyCloudThunk([currentItem]))
    }

    if (previewType === PREVIEW_TYPES.TIMELINE || previewType === PREVIEW_TYPES.FAVORITES) {
      dispatch(copyTimelineItemsToFamilyThunk([currentItem]))
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_COPY_TO_FC_CLICK)
    }

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_ADD_TO_FAMILYCLOUD)
  }, 500)

  const handleCopyToPersonal = _.debounce(() => {
    if (previewType === PREVIEW_TYPES.ALBUM || previewType === PREVIEW_TYPES.PLACE_ALBUM) {
      dispatch(copyAlbumItemsToPersonalCloudThunk([currentItem]))
    }

    if (previewType === PREVIEW_TYPES.TIMELINE) {
      dispatch(copyTimelineItemsToPersonalCloudThunk([currentItem]))
    }

    if (previewType === PREVIEW_TYPES.SHARED_ALBUM) {
      dispatch(addItemsToPersonalCloudThunk([currentItem]))
    }
  }, 500)

  const handleAddToAlbum = () => {
    dispatch(fetchAlbumsThunk())
    dispatch(appActions.setIsOpenedAddPhotosToAlbumModal(true))
    dispatch(appActions.setSelectedItemsToAddToAlbumModal([currentItem]))
    dispatch(appActions.setAlbumTypeToAddToAlbumModal(sdkType))
  }

  const handleInfo = () => {
    setOpenedInfoModal(true)
    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_INFO)
  }

  const handleDownload = () => {
    if (previewType === PREVIEW_TYPES.ALBUM || previewType === PREVIEW_TYPES.PLACE_ALBUM) {
      dispatch(downloadAlbumItemsThunk({ items: [currentItem], type: sdkType }))
    }

    if (previewType === PREVIEW_TYPES.TIMELINE || previewType === PREVIEW_TYPES.FAVORITES) {
      dispatch(downloadTimelineItemsThunk({ items: [currentItem], type: sdkType }))
    }

    if (previewType === PREVIEW_TYPES.SHARED_ALBUM) {
      dispatch(downloadSharedAlbumItemThunk({ item: currentItem }))
    }

    if (previewType === PREVIEW_TYPES.DOCUMENTS) {
      dispatch(downloadSelectedDocumentItems({ items: [currentItem] as any }))
    }

    if (isEnabledPreviewDuplicateMode) {
      analytics.push(ANALYTICS_EVENTS.WEB_SIMILAR_PHOTOS_DOWNLOAD_CLICK)
    }

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_DOWNLOAD)
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleRemove = () => {
    if (previewType === PREVIEW_TYPES.ALBUM) {
      isEnabledPreviewDuplicateMode && !!duplicateItems.length
        ? dispatch(removeSelectedItemsThunk({ items: [currentItem], type: SDK_TYPES.DEFAULT }))
        : dispatch(removeAlbumItemsThunk({ items: [currentItem], type: sdkType }))
      analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_REMOVE_CLICK)
    }

    if (previewType === PREVIEW_TYPES.TIMELINE || previewType === PREVIEW_TYPES.FAVORITES) {
      dispatch(removeSelectedItemsThunk({ items: [currentItem], type: sdkType }))
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_REMOVE_CLICK)
    }

    if (previewType === PREVIEW_TYPES.SHARED_ALBUM) {
      dispatch(removeSharedAlbumSelectedItemsThunk({ items: [currentItem] }))
    }

    if (previewType === PREVIEW_TYPES.DOCUMENTS) {
      dispatch(deleteDocumentItems({ itemsIds: [currentItem.id] }))
    }

    dispatch(albumActions.unselectAll())

    if (isEnabledPreviewDuplicateMode) {
      analytics.push(ANALYTICS_EVENTS.WEB_SIMILAR_PHOTOS_DELETE_CLICK)
    }

    handleCloseRemovingConfirmationModal()

    if (!!selectDuplicateItem && selectDuplicateItem.isOrigin) {
      handleClose()

      return
    }

    if (items.length === 1) {
      handleClose()

      return
    }

    if (currentIndex === items.length - 1) {
      dispatch(photoPreviewActions.setCurrentItemIndex(currentIndex - 1))

      return
    }

    analytics.push(ANALYTICS_EVENTS.WEB_PREVIEW_DELETE)
  }

  const handleShowHiddenItemsOnMobile = () => {
    toggleShowDropMenu(prev => !prev)
  }

  const handleRemoveFromFavorites = () => {
    dispatch(removeSelectedFromFavoritesItemsThunk({ items: [currentItem], type: sdkType }))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_REMOVE_FROM_FAVORITES)

    if (timelineFilter === TIMELINE_FILTERS.favorites) {
      if (!!selectDuplicateItem && selectDuplicateItem.isOrigin) {
        handleClose()

        return
      }

      if (items.length === 1) {
        handleClose()
        return
      }

      if (currentIndex === items.length - 1) {
        dispatch(photoPreviewActions.setCurrentItemIndex(currentIndex - 1))
        return
      }
    }
  }

  const handleAddToFavorites = () => {
    dispatch(addSelectedToFavoritesItemsThunk({ items: [currentItem], withNotification: true, type: sdkType }))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
  }

  const handleDuplicate = async () => {
    dispatch(getPhotosDuplicateThunk({ item: currentItem }))
  }

  const handleToggleFacesVisibility = () => {
    dispatch(photoPreviewActions.setAreFacesVisible(!areFacesVisible))
  }

  const onCloseInfoModal = () => {
    setOpenedInfoModal(false)
  }

  const handleTransformation = () => {
    dispatch(photoPreviewTransformerActions.setOnbordingModalOpen(true))
    dispatch(photoPreviewTransformerActions.setStartArray([currentItem]))
  }

  const handleShareAndCopyLinkItems = () => {
    dispatch(getPublicLinkAndCopyForPhotoThunk({
      items: [currentItem],
      type: sdkType === SDK_TYPES.DEFAULT ? SDK_TYPES.DEFAULT : SDK_TYPES.FAMILY,
      callback: handleShare
    }))
  }

  const handleOpenRestoreConfirmationModal = () => {
    toggleRestoreConfirmationModal(true)
  }

  const handleCloseRestoreConfirmationModal = () => {
    toggleRestoreConfirmationModal(false)
  }

  const handleOpenTrashBinRemovingConfirmationModal = () => {
    toggleTrashBinRemovingConfirmationModal(true)
  }

  const handleCloseTrashBinRemovingConfirmationModal = () => {
    toggleTrashBinRemovingConfirmationModal(false)
  }

  const handleRestore = () => {
    handleCloseRestoreConfirmationModal()

    dispatch(restoreTrashBinSelectedItemsThunk({ items: [currentItem] }))

    if (items.length === 1) {
      handleClose()

      return
    }

    if (currentIndex === items.length - 1) {
      dispatch(photoPreviewActions.setCurrentItemIndex(currentIndex - 1))
      
      return
    }
  }

  const handleDeleteFromTrashBin = () => {
    handleCloseTrashBinRemovingConfirmationModal()
    dispatch(deleteTrashBinItemsThunk({ items: [currentItem] }))

    if (items.length === 1) {
      handleClose()

      return
    }

    if (currentIndex === items.length - 1) {
      dispatch(photoPreviewActions.setCurrentItemIndex(currentIndex - 1))

      return
    }
  }

  const actionItems:any = (() => {
    if (previewType === PREVIEW_TYPES.TRASH_BIN) {
      return [
        {
          handler: handleOpenRestoreConfirmationModal,
          title: t('a_common_restore'),
          label: t('a_common_restore'),
          iconName: 'restore',
          hiddenOnMobile: false,
          orderOnMobile: 1,
          tooltipOff: isTablet
        },
        {
          handler: handleOpenTrashBinRemovingConfirmationModal,
          title: t('a_common_remove'),
          label: t('a_common_remove'),
          iconName: 'remove_icon',
          hiddenOnMobile: false,
          orderOnMobile: 2,
          tooltipOff: isTablet
        },
        {
          handler: handleClose,
          title: t('a_common_close'),
          label: t('a_common_close'),
          iconName: 'close',
          orderOnMobile: 3,
          tooltipOff: isTablet
        },
      ]
    }

    if (previewType === PREVIEW_TYPES.DOCUMENTS) {
      return [
        {
          handler: handleDownload,
          title: t('a_common_download'),
          label: t('a_common_download'),
          iconName: 'download_icon',
          hiddenOnMobile: false,
          tooltipOff: isTablet,
          orderOnMobile: 1,
        },
        {
          handler: handleOpenRemovingConfirmationModal,
          title: t('a_common_remove'),
          label: t('a_common_remove'),
          iconName: 'remove_icon',
          hiddenOnMobile: false,
          orderOnMobile: 2,
          tooltipOff: isTablet
        },
        {
          handler: handleClose,
          title: t('a_common_close'),
          label: t('a_common_close'),
          iconName: 'close',
          orderOnMobile: 3,
          tooltipOff: isTablet
        },
      ]
    }

    const items = [
      !!isLivePhotoCanPlay && {
        handler: handleTogglePlay,
        title: !isLivePhotoPlaying ? t('a_common_live_photo') : t('a_common_pause'),
        label: !isLivePhotoPlaying ? t('a_common_live_photo') : t('a_common_pause'),
        iconName: !!isLivePhotoPlaying ? 'stop' : 'play',
        hiddenOnMobile: false,
        hiddenOnDesktop: false,
        orderOnMobile: 0,
        tooltipOff: isTablet
      },
      {
        handler: handleDownload,
        title: t('a_common_download'),
        label: t('a_common_download'),
        iconName: 'download_icon',
        hiddenOnMobile: false,
        hiddenOnDesktop: false,
        orderOnMobile: 1,
        tooltipOff: isTablet
      },
      isAuthorized && isEnableTransformerPhoto && sdkType === SDK_TYPES.DEFAULT && currentItem?.type === 'image' && previewType === PREVIEW_TYPES.TIMELINE && {
        handler: handleTransformation,
        title: t('l_photos_AiTransformation'),
        label: t('l_photos_AiTransformation'),
        iconName: 'AI_transformer',
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        orderOnMobile: 2,
        tooltipOff: isTablet
      },
      !!facesData?.length && {
        handler: handleToggleFacesVisibility,
        title: areFacesVisible ? t('a_common_hideFaces') : t('a_common_showFaces'),
        label: areFacesVisible ? t('a_common_hideFaces') : t('a_common_showFaces'),
        iconName: areFacesVisible ? 'face_filled' : 'face',
        hiddenOnMobile: true,
        hiddenOnTablet: true,
        hiddenOnDesktop: true,
        orderOnMobile: 2,
        tooltipOff: isTablet
      },
      previewType === PREVIEW_TYPES.SHARED_ALBUM && isAuthorized && {
        handler: handleCopyToPersonal,
        title: t('a_common_addToMyCloud'),
        label: t('a_common_addToMyCloud'),
        iconName: 'add_photo_alternate_1_filled',
        hiddenOnMobile: false,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        orderOnMobile: 3,
        tooltipOff: isTablet
      },
      previewType === PREVIEW_TYPES.SHARED_ALBUM && isAuthorized && {
        handler: handleAddToAlbum,
        title: t('a_common_addToAlbum'),
        label: t('a_common_addToAlbum'),
        iconName: 'add_to_album_fullfiled',
        hiddenOnMobile: false,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        orderOnMobile: 4,
        tooltipOff: isTablet
      },
      previewType !== PREVIEW_TYPES.SHARED_ALBUM && (sdkType === SDK_TYPES.DEFAULT || sdkType === SDK_TYPES.FAMILY)  && (
        currentItem?.favorite ? {
          handler: handleRemoveFromFavorites,
          title: t('a_common_removeFavorite'),
          label: t('a_common_removeFavorite'),
          iconName: 'remove_favorite',
          hiddenOnMobile: false,
          hiddenOnTablet: false,
          hiddenOnDesktop: false,
          orderOnMobile: 2,
          tooltipOff: isTablet
        } : {
          handler: handleAddToFavorites,
          title: t('a_common_addToFavorites'),
          label: t('a_common_addToFavorites'),
          iconName: 'favorite_border',
          hiddenOnMobile: false,
          hiddenOnTablet: false,
          hiddenOnDesktop: false,
          orderOnMobile: 2,
          tooltipOff: isTablet
        }),
      previewType !== PREVIEW_TYPES.SHARED_ALBUM && {
        handler: handleShare,
        title: t('a_common_share'),
        label: t('a_common_share'),
        iconName: 'share',
        hiddenOnMobile: true,
        hiddenOnTablet: true,
        hiddenOnDesktop: true,
        orderOnMobile: 2,
        order: 2,
        tooltipOff: isTablet
      },
      previewType !== PREVIEW_TYPES.SHARED_ALBUM && {
        handler: handleShareAndCopyLinkItems,
        title: t('a_common_copyPublicLink'),
        label: t('a_common_copyPublicLink'),
        iconName: 'copy_link',
        hiddenOnMobile: true,
        hiddenOnTablet: true,
        hiddenOnDesktop: true,
        orderOnMobile: 3,
        order: 3,
        tooltipOff: isTablet
      },
      isEnabledPhotoDuplicates && currentItem?.type === 'image' && !isEnabledPreviewDuplicateMode && previewType !== PREVIEW_TYPES.SHARED_ALBUM && {
        handler: handleDuplicate,
        title: t('a_common_similar_photos'),
        label: t('a_common_similar_photos'),
        iconName: 'search_duplicate',
        hiddenOnMobile: true,
        hiddenOnTablet: !!isLivePhotoCanPlay,
        hiddenOnDesktop: !!isLivePhotoCanPlay,
        orderOnMobile: 5,
        order: 1,
        tooltipOff: isTablet
      },
      previewType !== PREVIEW_TYPES.SHARED_ALBUM && {
        handler: handleAddToAlbum,
        title: t('a_common_addToAlbum'),
        label: t('a_common_addToAlbum'),
        iconName: 'add_to_album_fullfiled',
        hiddenOnMobile: false,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        orderOnMobile: 3,
        tooltipOff: isTablet
      },
      previewType !== PREVIEW_TYPES.SHARED_ALBUM && !!userData?.family_user_id &&
      (sdkType === SDK_TYPES.DEFAULT ? {
        handler: handleCopyToFamily,
        title: t('a_common_copyToFamily'),
        label: t('a_common_copyToFamily'),
        iconName: 'add_from_family',
        hiddenOnMobile: false,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        orderOnMobile: 4,
        tooltipOff: isTablet
      } : {
        handler: handleCopyToPersonal,
        title: t('a_common_copyToPersonal'),
        label: t('a_common_copyToPersonal'),
        iconName: 'add_from_personal',
        hiddenOnMobile: false,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        orderOnMobile: 4,
        tooltipOff: isTablet
      }),
      {
        handler: handleInfo,
        title: t('l_common_info'),
        label: t('l_common_info'),
        iconName: 'info_preview',
        hiddenOnMobile: true,
        hiddenOnTablet: true,
        hiddenOnDesktop: previewType !== PREVIEW_TYPES.SHARED_ALBUM,
        popoverContent:
          !isTablet && (
            <>
              {t('l_common_size')}

            :
              {toMb(currentItem?.data_size)}

              {' '}

              <br />

              {t('l_common_added', { DATE: moment(currentItem?.created).format("DD.MM.YYYY, HH:mm") })}
            </>
          ),
        orderOnMobile: 4,
        order: 4,
        tooltipOff: isTablet
      },
      (previewType !== PREVIEW_TYPES.SHARED_ALBUM || (sharedPermission === 'write' && isAuthorized)) &&
      previewType !== PREVIEW_TYPES.PLACE_ALBUM && previewType !== PREVIEW_TYPES.CALENDAR && {
        handler: handleOpenRemovingConfirmationModal,
        title: previewType === PREVIEW_TYPES.ALBUM ? t('a_albums_removeFromAlbum') : t('a_common_delete'),
        label: previewType === PREVIEW_TYPES.ALBUM ? t('a_albums_removeFromAlbum') : t('a_common_delete'),
        iconName: 'remove_icon',
        hiddenOnMobile: true,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        orderOnMobile: 6,
        order: 5,
        tooltipOff: isTablet
      },
      isAuthorized && {
        handler: handleShowHiddenItemsOnMobile,
        title: t('l_common_showMore'),
        label: t('l_common_showMore'),
        iconName: 'action_container',
        hiddenOnTablet: false,
        hiddenOnDesktop: previewType === PREVIEW_TYPES.SHARED_ALBUM,
        orderOnMobile: 7,
        tooltipOff: isTablet
      },
      {
        handler: handleClose,
        title: t('a_common_close'),
        label: t('a_common_close'),
        iconName: 'close',
        orderOnMobile: 8,
        tooltipOff: isTablet
      },

    ].filter(item => !!item)

    const hiddenOnMobileItemsLength = items.filter(item => item.hiddenOnMobile).length

    if (!hiddenOnMobileItemsLength || items.length < 8) {
      const index = items.findIndex(item => item.iconName === 'action_container')

      if (index !== -1) {
        items.splice(index, 1)
      }
    }

    if (items.length < 8) {
      items.forEach(item => {
        item.hiddenOnMobile = false
        item.hiddenOnTablet = false
      })
    }

    return items
  })()

  const mobileActionItem = () => {
    if (isMobile) {
      return actionItems.filter(item => !!item.hiddenOnMobile)
    }

    if (isTablet) {
      return actionItems.filter(item => !!item.hiddenOnTablet)
    }

    return actionItems.filter(item => item.hiddenOnDesktop)
  }

  return (
    <PhotoPreviewToolbarBox className={className}
      ref={ref}
    >
      {
        actionItems.map(item => (
          <ActionItem
            key={item.title}
            item={item}
          />
        ))
      }

      {isShowDropMenu && (
        <DropMenuPreview
          isMobile={isMobile}
          menuItems={mobileActionItem()}
          handleClick={handleShowHiddenItemsOnMobile}
        />
      )}

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={previewType === PREVIEW_TYPES.ALBUM ?  t('l_common_headerRemovePhotos', { number: 1 }) : t('l_common_itemsDeleteTitle', { number: 1 })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemove}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {previewType === PREVIEW_TYPES.ALBUM ? t('l_common_removePhotosMsg', { number: 1 }) : t('l_common_itemsDeleteMsg', { number: 1 })}
        </TextInModalBox>
      </DialogModal>

      <InfoModalPreview
        titleText={t('a_photo_info')}
        isOpened={isOpenedInfoModal}
        handleCloseModal={onCloseInfoModal}
        size={toMb(currentItem?.data_size)}
        date={moment(currentItem?.created).format("DD.MM.YYYY, HH:mm")}
      />

      <DialogModal
        isOpened={restoreConfirmationModalOpened}
        title={t('l_trash_restoreConfirmation', { number: 1 })}
        onClose={handleCloseRestoreConfirmationModal}
        okText={t('a_common_restore')}
        onOk={handleRestore}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRestoreConfirmationModal}
        withLongButtons
      />

      <DialogModal
        isOpened={trashBinRemovingConfirmationModalOpened}
        title={t('l_common_deleteItemsConfirmation', { number: 1 })}
        onClose={handleCloseTrashBinRemovingConfirmationModal}
        okText={t('a_trash_deleteSelectedItems')}
        onOk={handleDeleteFromTrashBin}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseTrashBinRemovingConfirmationModal}
        withLongButtons
      />
    </PhotoPreviewToolbarBox>
  )
}

const ActionItem = ({ className = '', item }) => {
  const [popoverOpened, togglePopover] = useState(false)

  const handleClick = (event: MouseEvent) => {
    item.handler(event)
  }

  return (
    <>
      <ActionIconBox
        onClick={handleClick}
        onMouseEnter={() => togglePopover(true)}
        onMouseLeave={() => togglePopover(false)}
        data-tooltip-id={item.iconName + '_tooltipe'}
        data-tooltip-content={item.title}
        className={classNames(className, { 'hidden-on-desktop':  item.hiddenOnDesktop, 'hidden-on-tablet': item.hiddenOnTablet, 'hidden-on-mobile': item.hiddenOnMobile })}
        orderOnMobile={item.orderOnMobile}
      >
        <ActionIcon iconName={item.iconName} />

        {!!item.popoverContent && popoverOpened ? (
          <Popover>
            {item.popoverContent}
          </Popover>
        ) :
          !item.tooltipOff && (
            <Tooltip id={item.iconName + '_tooltipe'}
              style={{
                backgroundColor: "var(--background-primary)",
                color: "var(--text-primary)",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "160%"
              }}
            />
          )}
      </ActionIconBox>
    </>


  )
}


const TextInModalBox = styled.span`
  font-size: 14px;
`

const PhotoPreviewToolbarBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const ActionIcon = styled(SpriteIcon)`
  && {
    path {
      fill: white;
    }
  }
`

const Popover = styled.div`
  position: absolute;
  top: calc(100% + 11px);
  left: auto;
  right: 0;
  background: #fff;
  border-radius: 6px;
  width: 180px;
  color: #000;
  font-size: 12px;
  line-height: 1.4;
  padding: 9px 14px;
  text-align: left;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-bottom-color: #fff;
    border-style: solid;
    left: auto;
    right: 11px;
    top: -21px;
    bottom: auto;
    margin-left: -11px;
    border-width: 11px;
  }
`

const ActionIconBox = styled.button`
  width: 44px;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: background-color .3s ease;
  position: relative;
  z-index: 2;
  color: #DADADA;
  order: ${props => props.orderOnMobile};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  
  &:hover {
    background-color: rgba(255, 255, 255, .34);
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
    &.hidden-on-mobile {
      display: none;
    }
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    &.hidden-on-tablet {
      display: none;
    }

    &:hover {
      background-color: unset;
    }
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {

    &.hidden-on-tablet {
      display: flex;
    }

    &.hidden-on-desktop{
      display: none;
    }
  }
`
