import APP_CONFIG from 'constants/configs/app.config'
import { CLIENTS } from 'constants/clients'
import { STYLED_VARIABLES } from 'constants/styledVariables'
import { LEFT_MENU_ACTIVE_ITEMS } from 'constants/leftMenu'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  ExtraLinks,
  LeftMenuBottom,
  LeftMenuNavigation,
  SpriteIcon,
  UserMenu as UserMenuForUnauthorized
} from '@cloudike/web_ui_components'
import { getUiTheme, getUserDataSelector, getUserSettingsByPathSelector } from 'features/user/selectors'
import { useAppDispatch } from "store"
import { appActions } from 'store/app'
import { UserMenu } from 'features/user/UserMenu'
import i18n from "i18next"
import { getIsUserAuthorizedSelector, THEME } from 'features/user/userSlice'
import { USER_SETTINGS_PATHS } from 'features/user/constants'
import { isMobileDevice } from 'utils/isMobileDevice'

import { useMobileDetection } from '../hooks'
import { StorageWidget } from '../storage-widget'
import { useClientsQuery } from "../../../api/getClients"
import { analytics, ANALYTICS_EVENTS } from '../analytics'
import { redirectToExternalSSO } from "../../../utils/externalSSO"
import { useElectronDetection } from "../hooks/useElectronDetection"
import { SIDEBAR_LOGO_URL, SIDEBAR_LOGO_URL_DARK } from "../../../constants/configs/theme.config"
import { filesActions } from "../../files/filesSlice"
import { REDIRECT_TO } from "../../../constants/searchParams"
import { FAMILY_ONBOARDING_CLASSES } from "../../../constants/familyOnboarding"
import { getLeftMenuActiveItem, getMobileLeftMenuStatusSelector } from "../selectors"
import BetaVersionNotice from '../beta-version-notice'


const SidebarLogo = require(`../../../apps/${process.env.APP_NAME}/src/components/SidebarLogo`).default

const getMenuItemStateDependOfCurrentLocation = (currentPathname: string, expectedPathname: string | string[], strict = false, search = '') => {
  if (Array.isArray(expectedPathname)) {
    return expectedPathname.some(path => {
      if (!strict && currentPathname.startsWith(path)) {
        return true
      }

      if (strict && currentPathname === path) {
        return true
      }
    }) ? 'active' : 'default'
  }

  // Проверка добавлена для того чтобы при добавлении фото в семейный альбом из разных таймлайнов
  // активным полем в LeftSideBar оставлось семейные альбомы
  if (!strict && search.includes(REDIRECT_TO) && search.includes('family')) {
    return expectedPathname.includes('family') ? 'active' : 'default'
  }


  if (!strict && currentPathname.startsWith(expectedPathname)) {
    return 'active'
  }

  if (strict && currentPathname === expectedPathname) {
    return 'active'
  }

  return 'default'
}

export const LeftSidebar = ({ className = '', isLeftMenuHidden, isHoveredLeftMenu, isHoveredLeftMenuFromArrow, onClickArrowHidden, onClickMenuButton }) => {
  const { t } = useTranslation()
  const { pathname , search } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [appsLinks, setAppsLinks] = useState([])

  const isMobile = useMobileDetection()
  const isMobileByUserAgent = isMobileDevice()
  const isElectronAvailable = useElectronDetection()
  const leftMenuActiveItem = getLeftMenuActiveItem()

  const userData = getUserDataSelector()
  const isAuthorized = getIsUserAuthorizedSelector()
  const documentWalletSettings = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.USER_DOCUMENT_WALLET)
  const { data, isLoading: isClientsLoading } = useClientsQuery()
  const isLeftSidebarActiveOnMobile = getMobileLeftMenuStatusSelector()
  const theme = getUiTheme()
  console.log('isElectronAvailable', isElectronAvailable)

  useEffect(() => {
    if (!isClientsLoading) {
      const clients = data?._embedded?.clients || []
      if (clients.length) {
        const links = clients.filter(client => isMobileByUserAgent ? !['win-client', 'macos-client'].includes(client.id) : true).map(mapClientsToBadge)
        setAppsLinks(links)
      }
    }
  }, [isClientsLoading, userData.lang])

  function mapClientsToBadge(client) {
    const platform = client.id.slice(0, -'-client'.length)
    const { LABELS, BADGE_FILE_NAMES } = CLIENTS
    const lang = i18n.resolvedLanguage

    const onGoTo = (href: string) => {
      if (client.type === 'android-client') {
        analytics.push(ANALYTICS_EVENTS.WEB_GOOGLE_PLAY_CLICK)
      }

      if (client.type === 'ios-client') {
        analytics.push(ANALYTICS_EVENTS.WEB_APP_STORE_CLICK)
      }

      if (client.type === 'win-client') {
        analytics.push(ANALYTICS_EVENTS.WEB_DESK_DOWNLOAD_WIN_CLICK)
      }

      if (client.type === 'macos-client') {
        analytics.push(ANALYTICS_EVENTS.WEB_DESK_DOWNLOAD_MAC_CLICK)
      }

      window.location.href = href
    }

    return {
      badgeUrl: BADGE_FILE_NAMES[platform][lang],
      href: client.download_link,
      label: LABELS[client.type] || '',
      onGoTo
    }
  }

  const createMenuItemClickHandler = useCallback((url, redirect = false, callback = () => undefined) => () => {
    callback()
    dispatch(filesActions.setActiveSearch(false))
    dispatch(filesActions.setSearchType(null))

    if (redirect) {
      window.location.href = url

      return
    }

    if (isMobile) {
      dispatch(appActions.toggleLeftMenuOnMobile(false))
    }

    navigate(url)
  }, [isMobile])

  const handleMenuItemClick = useCallback((menuItem) => {
    menuItem.callBack()
  }, [])

  const menuItems = useMemo(() => [
    {
      label: t('a_nav_personal'),
      labelIcon: 'user_outlined',
      elements: [
        {
          label: t('l_common_myFiles'),
          name: t('l_common_myFiles'),
          state: getMenuItemStateDependOfCurrentLocation(pathname, '/drive'),
          iconName: 'files',
          callBack: createMenuItemClickHandler('/drive', false)
        },
        {
          label: t('a_nav_timeline'),
          name: t('a_nav_timeline'),
          state: leftMenuActiveItem === LEFT_MENU_ACTIVE_ITEMS.TIMELINE ? 'active' : getMenuItemStateDependOfCurrentLocation(pathname, '/photos', true),
          iconName: 'gallery',
          callBack: createMenuItemClickHandler('/photos', false, () => {
            analytics.push(ANALYTICS_EVENTS.WEB_TIMELINE_CLICK)
          })
        },
        {
          label: t('a_nav_myAlbums'),
          name: t('a_nav_myAlbums'),
          state: getMenuItemStateDependOfCurrentLocation(pathname, '/photos/albums', false, search),
          iconName: 'album',
          callBack: createMenuItemClickHandler('/photos/albums', false, () => {
            analytics.push(ANALYTICS_EVENTS.WEB_MY_ALBUMS_CLICK)
          })
        },

        {
          label: t('a_nav_documentWallet'),
          name: t('a_nav_documentWallet'),
          state: getMenuItemStateDependOfCurrentLocation(pathname, '/documents'),
          iconName: 'passport',
          callBack: createMenuItemClickHandler(`/documents`, false, () => {
            analytics.push(ANALYTICS_EVENTS.WEB_DOCUMENTS_VIEW)
          }),
          isHidden: !documentWalletSettings?.enabled
        },
      ]
    },
    {
      label: t('l_nav_family'),
      labelIcon: 'family_group',
      className: FAMILY_ONBOARDING_CLASSES.STEP_1,
      elements: [
        {
          label: t('a_nav_familyTimeline'),
          name: t('a_nav_familyTimeline'),
          state: leftMenuActiveItem === LEFT_MENU_ACTIVE_ITEMS.FAMILY_TIMELINE ? 'active' : getMenuItemStateDependOfCurrentLocation(pathname, '/family/photos', true),
          iconName: 'gallery',
          callBack: createMenuItemClickHandler('/family/photos', false, () => {
            analytics.push(ANALYTICS_EVENTS.WEB_FC_TIMELINE_CLICK)
          }),
        },
        {
          label: t('a_nav_familyAlbums'),
          name: t('a_nav_familyAlbums'),
          state: getMenuItemStateDependOfCurrentLocation(pathname, '/family/photos/albums', false, search),
          iconName: 'album',
          callBack: createMenuItemClickHandler('/family/photos/albums', false, () => {
            analytics.push(ANALYTICS_EVENTS.WEB_FC_ALBUMS_CLICK)
          }),
        },
        // {
        //   label: t('a_nav_favorites'),
        //   name: t('a_nav_favorites'),
        //   state: getMenuItemStateDependOfCurrentLocation(pathname, '/family/photos/favorites', true),
        //   iconName: 'heart',
        //   callBack: createMenuItemClickHandler('/family/photos/favorites', false, () => {
        //     analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_FAVORITES_CLICK)
        //   }),
        //   isHidden: !userData?.family_user_id
        // },
        {
          label: t('a_nav_familyManage'),
          name: t('a_nav_familyManage'),
          state: getMenuItemStateDependOfCurrentLocation(pathname, '/family/manage'),
          iconName: 'family_settings',
          callBack: createMenuItemClickHandler(`/family/manage`, false, () => {
            analytics.push(ANALYTICS_EVENTS.WEB_FC_MANAGE_CLICK)
          }),
          className: FAMILY_ONBOARDING_CLASSES.STEP_3,
        }
      ]
    },
    {
      label: t('l_nav_more'),
      labelIcon: 'more_horiz',
      elements: [
        {
          label: t('a_common_uploads'),
          name: t('a_common_uploads'),
          state: getMenuItemStateDependOfCurrentLocation(pathname, '/tasks'),
          iconName: 'cloud_upload',
          callBack: createMenuItemClickHandler(`/tasks`, true),
          isHidden: !isElectronAvailable
        },
        // {
        //   label: t('a_nav_publicLinks'),
        //   name: t('a_nav_publicLinks'),
        //   state: getMenuItemStateDependOfCurrentLocation(pathname, [ '/photos/shared-by-me', '/photos/family/shared-by-me']),
        //   iconName: 'link',
        //   callBack: createMenuItemClickHandler(`/photos/shared-by-me`, false, () => {
        //     analytics.push(ANALYTICS_EVENTS.WEB_PUBLIC_LINKS_CLICK)
        //   }),
        // },
        {
          label: t('l_trash_trashBinTitle'),
          name: t('l_trash_trashBinTitle'),
          state: getMenuItemStateDependOfCurrentLocation(pathname, '/global-trash'),
          iconName: 'trash_bin',
          callBack: createMenuItemClickHandler(`/global-trash/files`, false, () => {
            analytics.push(ANALYTICS_EVENTS.WEB_TRASH_BIN_CLICK)
          }),
        }
      ]
    }
  ], [pathname, userData, t, documentWalletSettings?.enabled, isElectronAvailable, leftMenuActiveItem])

  const handleClickFeedback = () => {
    dispatch(appActions.setFeedbackModalVisibility(true))
    analytics.push(ANALYTICS_EVENTS.WEB_FEEDBACK_CLICK)
  }

  const handleSignIn = () => {
    redirectToExternalSSO()
  }

  const handleSignUp = () => {
    redirectToExternalSSO('/signup')
  }

  const handleClickStorageWidget = () => {
    navigate('/profile/log')
  }

  return (
    <>
      <LeftSidebarBox className={className}
        paddingBottom={!!APP_CONFIG.is_feedback_in_left_menu && !!appsLinks?.length ? '90px' : '50px'}
      >
        {
          isHoveredLeftMenu ? (
            <LogoBox isHoveredLeftMenu={isHoveredLeftMenu}>
              {theme === THEME.LIGHT ? (
                <SidebarLogo
                  url={SIDEBAR_LOGO_URL}
                  title={APP_CONFIG.app_name}
                />
              ) : (
                <SidebarLogo
                  url={SIDEBAR_LOGO_URL_DARK}
                  title={APP_CONFIG.app_name}
                />
              )}

              {!isLeftSidebarActiveOnMobile && isHoveredLeftMenuFromArrow && (
                <ArrowBox onClick={onClickArrowHidden}>
                  <ArrowIcon
                    isLeftMenuHidden={isLeftMenuHidden}
                    iconName={'two_arrow_right'}
                  />
                </ArrowBox>
              )}
            </LogoBox>
          ) : (
            <LogoBox
              onClick={onClickMenuButton}
              isHoveredLeftMenu={isHoveredLeftMenu}
            >
              <HambergerIcon iconName={'big_hamburger_menu'} />
            </LogoBox>
          )
        }

        {
          isAuthorized ? (
            <>
              <UserMenuContainer>
                <StyledUserMenu />
              </UserMenuContainer>

              <StyledMobileStorageWidget onClick={handleClickStorageWidget} />

              <LeftMenuNavigation
                isLeftMenuHidden={isLeftMenuHidden}
                isHoveredLeftMenu={isHoveredLeftMenu}
                menuItemsList={menuItems}
                onMenuItemSelection={handleMenuItemClick}
              />
            </>
          ) : (
            <StyledUserMenuForUnauthorized
              onLogIn={handleSignIn}
              onCreateAccount={handleSignUp}
              texts={{
                logIn: t('a_common_logIn'),
                or: t('l_common_or'),
                createAccount: t('a_common_signUp')
              }}
            />
          )
        }

        {
          isHoveredLeftMenu ? (
            <>
              <LeftMenuBottom
                firstRowContent={!!APP_CONFIG.is_feedback_in_left_menu ? (
                  <FeedbackBox onClick={handleClickFeedback}>
                    <FeedbackIcon iconName={'feedback'} />

                    <FeedbackText>
                      {t('l_more_aboutFeedbackHint')}
                    </FeedbackText>
                  </FeedbackBox>
                ) : null}
                secondRowContent={!!appsLinks?.length ? (
                  <ExtraLinks
                    beforeIconName={!!APP_CONFIG.is_feedback_in_left_menu && "download"}
                    iconName="expand_more"
                    label={t('a_common_downloadApp')}
                    items={appsLinks}
                  />
                ) : null}
              />

              {isAuthorized && <StyledStorageWidget onClick={handleClickStorageWidget} />}

              {isElectronAvailable && <BetaVersionNotice />}
              
            </>
          ) : (
            <IconsBottomBox>
              <SStorageIcon>
                <SSpriteIcon iconName={'cloud_left_menu'} />
              </SStorageIcon>

              <SAppIcon iconName={'phone_download_app'} />
            </IconsBottomBox>
          )
        }
      </LeftSidebarBox>
    </>
  )
}

const HambergerIcon = styled(SpriteIcon)``

const ArrowBox = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  
  :hover{
    background: var(--background-tertiary);
    cursor: pointer;
  }
`

const ArrowIcon = styled(SpriteIcon)`
  width: 15px;
  height: 15px;
  rotate: ${(props:any) => props.isLeftMenuHidden ? '' : '180deg'};
`

const IconsBottomBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: auto;
`

const SAppIcon = styled(SpriteIcon)`
  color: var(--icon-primary);
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 16px;
`

const SSpriteIcon = styled(SpriteIcon)`
  color: var(--icon-primary);
  width: 24px;
  height: 24px;
`

const SStorageIcon  = styled.div`
  width: 40px;
  height: 40px;
  background: var(--background-primary);
  border: 1px solid var(--divider-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`

const StyledUserMenuForUnauthorized = styled(UserMenuForUnauthorized)`
  padding: 0 24px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const LeftSidebarBox = styled.div`
  background: var(--background-secondary);
  border-right: 1px solid var(--divider-primary);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.paddingBottom};

  .sidebar-menu {
    width: 100%;
    background: transparent;

    .sidebar-menu__group-title {
      text-transform: uppercase;
    }
  }
  
  :hover{
    
  }
`

const LogoBox = styled.div`
  margin-bottom: 16px;
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  background: var(--background-secondary);
  border-bottom: 1px solid var(--divider-primary);
  display: flex;
  justify-content: ${(props:any) => props.isHoveredLeftMenu ? 'space-between' : 'center'};
`

const UserMenuContainer = styled.div`
  width: 100%;
  padding: 0 4px 0 23px;

  .userbar {
    width: 100%;

    .userbar__container {
      justify-content: flex-start;
      position: relative;
      margin: 0;

      .dropDown__usermenu {
        left: 0;
        width: 100%;
      }
    }
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledUserMenu = styled(UserMenu)`
  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledStorageWidget = styled(StorageWidget)`
  margin-top: auto;
  margin-left: 16px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledMobileStorageWidget = styled(StorageWidget)`
  margin-left: 16px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const FeedbackBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const FeedbackIcon = styled(SpriteIcon)`
  width: 16px !important;
  height: 16px !important;
  color: var(--text-secondary);
  margin-right: 8px;
  margin-left: 5px;
`

const FeedbackText = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: var(--text-secondary);
`

