import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { getModalsRootElement } from 'constants/modals'

import React, { useCallback, useEffect } from 'react'

import { PrimaryButton, SecondaryButton } from '@cloudike/web_ui_components'
import { useMobileDetection } from 'features/common/hooks'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import _ from "lodash"
import { useAppDispatch } from 'store'
import { Spinner } from 'ui/Spinner'
import { useKeyDownTransformer } from 'features/common/hooks/useKeyDownTransformer'
import { uploadTransformerThunk } from 'features/common/files-uploading/filesUploadingSlice'
import ReactDOM from 'react-dom'

import { AITransformationSwitcher } from './AITransformationSwitcher'
import { AITransformationPhotos } from './AITransformationPhotos'
import { AITransformationSkeleton } from './AITransformationSkeleton'
import { getAIEditorActiveSelector, 
  getIntervalIdsArraySelector, 
  getLoadingAITransformationStatusSelector, 
  getPhotoPreviewTransformerErrorSelector, 
  getPhotoPreviewTransformerItemsSelector, 
  getPreviewTransformerOpenSelector, 
  getStartArraySelector, 
  getUploadLoadingSelector } from './selectors'
import { fetchTransformerThunk, photoPreviewTransformerActions } from './photoPreviewTransformerSlice'
import { AITransformationError } from './AITransformationError'

const AITransformationPhoto: React.FC<{
  isAIEditorActive: boolean;
  onChange: () => void;
}> = React.memo(({ isAIEditorActive, onChange }) => {
  const { t } = useTranslation()

  return (
    <PhotoContainer>
      <PhotoBox>
        <AITransformationPhotos />
      </PhotoBox>

      <AITransformationSwitcher         
        leftOption={t('l_photos_AIEditor')}
        rightOption={t('l_photos_original')}
        isLeftActive={isAIEditorActive}
        onChange={onChange}
      />
    </PhotoContainer>
  )
})

export const AITransformationPreview = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isMobile = useMobileDetection()
  const isAIEditorActive = getAIEditorActiveSelector()
  const hasError = getPhotoPreviewTransformerErrorSelector()
  const isPreviewTransformerOpen = getPreviewTransformerOpenSelector()
  const loadingTransformationStatus = getLoadingAITransformationStatusSelector()
  const intervalIdsArray = getIntervalIdsArraySelector()
  const originalItems = getStartArraySelector()
  const itemsTransform = getPhotoPreviewTransformerItemsSelector()
  const isUploadLoading  = getUploadLoadingSelector()
  const itemTransform = itemsTransform[0]  
  const originalItem = originalItems[0]

  const handleChange = _.debounce(() => {
    dispatch(photoPreviewTransformerActions.toggleAIEditorActive())
  }, 300)

  const onButtonSecondaryClick = useCallback(() => {
    dispatch(photoPreviewTransformerActions.setPreviewTransformerOpen(false))
  }, [])

  const onButtonPrimaryClick = () => {
    const time = Number(originalItem.created_original) + 1
    const file = {
      source_link: itemTransform?.results[0],
      file_name: itemTransform?.file_name,
      meta_data: {
        client_data: {
          created: time,
          modified: time,
        }
      }
    }

    dispatch(uploadTransformerThunk({ file }))
  }

  const handleError = () => {
    dispatch(photoPreviewTransformerActions.setError(true))
    dispatch(photoPreviewTransformerActions.setLoadingAITransformationStatus(LOADING_STATUSES.FAILED))
  }

  const handleCallback = (data: any) => {
    if (!data.error) {
      dispatch(photoPreviewTransformerActions.addTransformedPhoto(data))
      dispatch(photoPreviewTransformerActions.setLoadingAITransformationStatus(LOADING_STATUSES.SUCCEEDED))
    } else {
      handleError()
    } 
    dispatch(photoPreviewTransformerActions.downInProgress())
  }

  useEffect(() => {
    if (isPreviewTransformerOpen) {
      const hasImagePreview = originalItem._links?.image_preview?.href
    
      if (!hasImagePreview) {
        handleError()
      } else {
        dispatch(photoPreviewTransformerActions.setLoadingAITransformationStatus(LOADING_STATUSES.LOADING))
        dispatch(fetchTransformerThunk({ callback: handleCallback }))
      }
    } else {
      intervalIdsArray.forEach(clearInterval)
      dispatch(photoPreviewTransformerActions.resetState())
    }
  },[isPreviewTransformerOpen])

  useKeyDownTransformer(27, onButtonSecondaryClick, isPreviewTransformerOpen)

  return ReactDOM.createPortal(
    <>
      { isPreviewTransformerOpen && (
        <Container>
          { !hasError && (
            <Header>
              {!isMobile && (
                <Title>
                  {t('l_photos_AiTransformation')}
                </Title>
              )}

              { isMobile ? (
                <ButtonsGroupMobile>
                  <ButtonMobile onClick={onButtonSecondaryClick}>
                    {t('a_common_cancel')}
                  </ButtonMobile>

                  <ButtonMobile onClick={onButtonPrimaryClick}
                    disabled={loadingTransformationStatus === LOADING_STATUSES.LOADING || isUploadLoading}
                  > 
                    <ButtoBox>
                      { isUploadLoading && <SSpinner color='var(--white)' />}

                      {t('a_common_saveASCopy')}
                    </ButtoBox>
                    
                  </ButtonMobile>
                </ButtonsGroupMobile>
              ) : (
                <ButtonsGroup>
                  <StyledSecondaryButton
                    actionName={t('a_common_cancel')}
                    onAction={onButtonSecondaryClick}
                  />

                  <StyledPrimaryButton
                    actionName={t('a_common_saveASCopy')}
                    onAction={onButtonPrimaryClick}
                    disabled={loadingTransformationStatus === LOADING_STATUSES.LOADING || isUploadLoading}
                    firstChildren={isUploadLoading && <SSpinner color='var(--white)' />}
                  />
                </ButtonsGroup>
              )
              }

            </Header>
          )}

          { loadingTransformationStatus === LOADING_STATUSES.LOADING && !hasError && <AITransformationSkeleton />}

          { loadingTransformationStatus === LOADING_STATUSES.SUCCEEDED && !hasError && (
            <AITransformationPhoto 
              isAIEditorActive={isAIEditorActive}
              onChange={handleChange}
            />
          )}

          { hasError && <AITransformationError />}

        </Container>
      )
      }
    </>, getModalsRootElement()
  )
}

const ButtoBox = styled.div`
  display: flex;
  align-items: center;
  color: var(--transformer-button-primary-default);

  :hover {
    color: var(--transformer-button-primary-hover);
  }

  :disabled {
    color: var(--transformer-button-primary-disabled);
  }
`

const SSpinner = styled(Spinner)`
  width: 30px;
  height: 30px;
  margin-right: 8px;
`

const PhotoBox = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 16px;

  &:focus {
    outline: none;
  }
`

const PhotoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 60px);
  padding-top: 16px;
`

const ButtonsGroupMobile = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ButtonMobile = styled.button`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 

  :disabled {
    color: #6A6A6F;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 17;
  background-color: var(--transformer-background-primary);
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  flex-shrink: 0;
`

const Title = styled.div`
  height: 24px;
  color: var(--transformer-text-primary);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.906%;
`

const ButtonsGroup = styled.div`
  position: absolute;
  right: 12px;
  display: flex;
  gap: 12px;
`

const StyledSecondaryButton = styled(SecondaryButton)`
  background-color: var(--transformer-background-primary);
  color: var(--transformer-text-primary);
  border-color: var(--transformer-button-secondary-default);

  :hover {
    background-color: var(--transformer-button-secondary-hover);
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  background-color: var(--transformer-button-primary-default);
  color: var(--transformer-button-primary-text);

  :disabled {
    background-color: var(--transformer-button-primary-disabled);
    color: var(--transformer-button-primary-text-disabled);

    :hover {
      background-color: var(--transformer-button-primary-disabled);
      color: var(--transformer-button-primary-text-disabled);
    }
  }

  :hover {
    background-color: var(--transformer-button-primary-hover);
    color: var(--transformer-button-primary-text);
  }
`
