import { Layout, STYLED_VARIABLES } from "@cloudike/web_ui_components"
import { useAppDispatch } from  "store"
import { appActions } from "store/app"
import styled from 'styled-components'
import classNames from "classnames"

import useScrollToTopAfterLocationChanging from "../hooks/useScrollToTopAfterLocationChanging"
import {
  getAreLayoutSidebarsVisible,
  getIsMainContainerWithFullWidth,
  getIsRightSidebarTransparentSelector,
  getLayoutContentOverflowSelector,
  getMobileLeftMenuStatusSelector,
  getMobileRightMenuStatusSelector
} from "../selectors"
import { getIsActiveSearchSelector } from "../../files/selectors"

type ConnectedLayoutProps = Pick<React.ComponentProps<typeof Layout>, 'children' | 'className' | 'leftSidebar' | 'rightSidebar' | 'leftMenuRef' | 'isHoveredLeftMenu' | 'isLeftMenuHidden'>

export const ConnectedLayout: React.FC<ConnectedLayoutProps> = (props) => {
  const dispatch = useAppDispatch()

  const isLeftSidebarActiveOnMobile = getMobileLeftMenuStatusSelector()
  const isRightSidebarActiveOnMobile = getMobileRightMenuStatusSelector()
  const isLayoutOverflowDisabled = getLayoutContentOverflowSelector()
  const isRightSidebarTransparent = getIsRightSidebarTransparentSelector()
  const areLayoutSidebarsVisible = getAreLayoutSidebarsVisible()
  const isActiveFileSearch = getIsActiveSearchSelector()
  const isMainContainerWithFullWidthOnMobile = getIsMainContainerWithFullWidth()

  useScrollToTopAfterLocationChanging()

  const handleCloseLeftSidebar = () => {
    dispatch(appActions.toggleLeftMenuOnMobile(false))
  }

  const handleCloseRightSidebar = () => {
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  return (
    <LayoutBox
      {...props}
      className={classNames(props.className, { 
        'no-overflow': isLayoutOverflowDisabled, 
        'with-transparent-right-sidebar': isRightSidebarTransparent, 
        'without-sidebars': !areLayoutSidebarsVisible, 
        'full-width-on-mobile': isMainContainerWithFullWidthOnMobile 
      })}
      isLeftSidebarActiveOnMobile={isLeftSidebarActiveOnMobile}
      isRightSidebarActiveOnMobile={isRightSidebarActiveOnMobile}
      onCloseLeftSidebar={handleCloseLeftSidebar}
      onCloseRightSidebar={handleCloseRightSidebar}
      isActiveFileSerch={isActiveFileSearch}
    />
  )
}

const LayoutBox = styled(Layout)`
  // &&& override styles with higher specificity
  &&& {
    position: relative;
    height: 100%;
    
    main {
      overflow: hidden;
      padding: ${(props: any) => {return props.isActiveFileSerch ? 'unset' : ''}};
    }

    &.no-overflow {
      main {
        overflow: unset;
      }
    }
    
    &.with-transparent-right-sidebar {
      aside {
        background: transparent;
      }
    }
    
    &.without-sidebars {
      grid-template-columns: unset;
      
      aside {
        display: none;
      }
    }

    @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_90}) {
      &.full-width-on-mobile {

      main {
        width: 100%;
        padding: 20px 0 0;
        margin-right: 0;
      }
    }
  }
}
`
