import { LOADING_STATUSES } from "constants/loadingStatuses"
import { STORAGE_CONSTANTS } from "constants/storage"

import React, { useCallback, useEffect } from "react"

import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import {
  albumsActions,
  fetchAlbumsThunk,
  subscribeAlbumsToWSThunk,
  unsubscribeAlbumsFromWSThunk
} from "features/albums/albums-list/albumsSlice"
import { albumsPlacesActions, fetchPlacesAlbumsThunk } from "features/albums/albums-places/albumsPlacesSlice"
import { Albums } from "features/albums/albums-list/Albums"
import {
  getAlbumsItemsSelector,
  getAlbumsLoadingStatusSelector,
  getAlbumsSelectedItemsCountSelector,
  getLoadingAlbumSelector
} from "features/albums/albums-list/selectors"
import { SDK_TYPES } from "sdk/sdkConstants"
import { AlbumSkeleton, PhotosGrid } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { EmptyAlbumsListPlaceholder } from "features/albums/albums-list/EmptyAlbumsListPlaceholder"
import { useNavigate } from "react-router-dom"
import { albumActions, createNewEmptyAlbumThunk } from "features/albums/album/albumSlice"
import { useMobileDetection } from "features/common/hooks"
import { appActions } from "store/app"
import { subscribeFamilyToWSThunk, unsubscribeFamilyFromWSThunk } from "features/family/familySlice"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { saveNavigationPathname } from "utils/navigationStorage"

import { WithSearch } from "../../features/photo-search/WithSearch"
import { albumsSeasonsActions, fetchSeasonsAlbumsThunk } from "../../features/albums/albums-season/albumsSeasonsSlice"
import {
  getAlbumsSeasonsItemsSelector,
  getAlbumsSeasonsLoadingStatusSelector,
  getAlbumsSeasonsSelectedItemsCountSelector
} from "../../features/albums/albums-season/selectors"
import { getAlbumsPlacesItemsSelector } from "../../features/albums/albums-places/selectors"
import { albumsPersonActions, fetchPersonAlbumsThunk } from "../../features/albums/albums-person/albumPersonSlice"
import { getAlbumsPersonWithNoneZeroContent } from "../../features/albums/albums-person/selectors"
import { timelineActions } from "../../features/photo/timeline/timelineSlice"
import {
  albumsMomentsActions,
  fetchFirstMomentsAlbumsThunk
} from "../../features/albums/albums-moments/albumsMomentsSlice"
import { getAlbumsMomentsItemsSelector } from "../../features/albums/albums-moments/selectors"


interface AlbumsPageProps {
    type: SDK_TYPES
}

const AlbumsPage: React.FC<AlbumsPageProps> = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isMobile = useMobileDetection()
  const isLoading = getLoadingAlbumSelector()

  const selectedItemsCount = getAlbumsSelectedItemsCountSelector() + getAlbumsSeasonsSelectedItemsCountSelector()

  const status = getAlbumsLoadingStatusSelector()
  const statusLoadingSeasonsAlbums = getAlbumsSeasonsLoadingStatusSelector()


  const items = [...getAlbumsItemsSelector(), ...getAlbumsPlacesItemsSelector(), ...getAlbumsSeasonsItemsSelector(), ...getAlbumsPersonWithNoneZeroContent(), ...getAlbumsMomentsItemsSelector()]
  usePageTitle('a_files_uploadPhotoAlbums_title')

  useEffect(() => {
    saveNavigationPathname(STORAGE_CONSTANTS.BEFORE_PHOTOS_SEARCH_PATHNAME, window.location.pathname)
    dispatch(subscribeAlbumsToWSThunk())
    dispatch(albumsActions.setCurrentAlbumsType(type))
    dispatch(albumActions.setCurrentAlbumsType(type))
    dispatch(timelineActions.setCurrentTimelineType(type))
    dispatch(fetchAlbumsThunk())
    dispatch(albumsPlacesActions.setCurrentAlbumsType(type))
    dispatch(albumsPersonActions.setCurrentAlbumsType(type))
    dispatch(albumsMomentsActions.setCurrentAlbumsType(type))
    dispatch(fetchPlacesAlbumsThunk())
    dispatch(fetchFirstMomentsAlbumsThunk())
    dispatch(fetchPersonAlbumsThunk({}))

    if (type === SDK_TYPES.DEFAULT) {
      dispatch(fetchSeasonsAlbumsThunk(3))
    }

    if (type === SDK_TYPES.FAMILY) {
      dispatch(subscribeFamilyToWSThunk({ navigate }))
    }

    return () => {
      dispatch(albumsActions.resetState())
      dispatch(albumsPlacesActions.resetState())
      dispatch(albumsSeasonsActions.resetState())
      dispatch(albumsPersonActions.resetState())

      dispatch(unsubscribeAlbumsFromWSThunk())

      if (type === SDK_TYPES.FAMILY) {
        dispatch(unsubscribeFamilyFromWSThunk())
      }
    }
  }, [])

  const handleCancelSelection = useCallback(() => {
    dispatch(albumsActions.unselectAll())
    dispatch(albumsSeasonsActions.unselectAll())
  }, [])

  const handleCreateNewAlbum = () => {
    if (isLoading) return
    const callback = (id: string) => {
      if (type === SDK_TYPES.DEFAULT) {
        navigate(`/photos/albums/${id}`)
      }

      if (type === SDK_TYPES.FAMILY) {
        navigate(`/family/photos/albums/${id}`)
      }
    }

    if (selectedItemsCount > 0) {
      dispatch(appActions.toggleRightMenuOnMobile(true))

      return
    }

    dispatch(createNewEmptyAlbumThunk({ type, name: '', callback }))
    dispatch(albumActions.toggleAlbumNameEditStatus(true))
  }

  const pageContent = (
    <>
      {
        (status === LOADING_STATUSES.LOADING || (type === SDK_TYPES.DEFAULT && statusLoadingSeasonsAlbums === LOADING_STATUSES.LOADING)) &&
                <Skeleton />
      }

      {
        (status !== LOADING_STATUSES.LOADING || statusLoadingSeasonsAlbums !== LOADING_STATUSES.LOADING) && !!items.length && (
          <Albums type={type} />
        )
      }

      {
        (status !== LOADING_STATUSES.LOADING || statusLoadingSeasonsAlbums !== LOADING_STATUSES.LOADING) && !items.length && (
          <EmptyAlbumsListPlaceholder type={type} />
        )
      }

      {
        !!items.length && (
          <MobileToolbarMenuToggle
            iconName={selectedItemsCount > 0 ? 'more_horiz' : 'add'}
            onClick={handleCreateNewAlbum}
          />
        )}
    </>
  )

  return (
    <WithSearch
      isSearchHidden={!!selectedItemsCount}
      defaultPageContent={pageContent}
      type={type}
    >
      <PageTopbar
        title={t('l_albums_albumsTitle')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
      />
    </WithSearch>
  )
}

const Skeleton = () => {
  return (
    <PhotosGrid>
      {Array.from({ length: 16 }).map((_, index: number) => <AlbumSkeleton key={index} />)}
    </PhotosGrid>
  )
}

export default AlbumsPage
