import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { AlbumCard, AlbumPlaceCard, AlbumsGrid, AlbumsPlacesGrid, STYLED_VARIABLES, } from '@cloudike/web_ui_components'
import _ from "lodash"

import { getAlbumDateStr } from '../utils/albumDate'
import { getAlbumsPlacesItemsSelector, getAlbumsPlacesTotalCountSelector } from "../albums-places/selectors"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"
import { getAlbumsSeasonsItemsSelector, getAlbumsSeasonsTotalCountSelector } from "../albums-season/selectors"
import { AlbumsPlacesTitle } from "../albums-places/AlbumsPlacesTitle"
import { AlbumsSeasons } from "../albums-season/AlbumsSeasons"
import { getAlbumsPersonWithNoneZeroContent } from "../albums-person/selectors"
import { AlbumsPersonList } from "../albums-person/AlbumsPersonList"
import { getPreviewAlbumLink } from "../../../utils/getPreviewAlbumLink"
import { getAlbumsMomentsItemsSelector } from "../albums-moments/selectors"
import { AlbumsMomentsTitle } from "../albums-moments/AlbumsMomentsTitle"

import { albumsActions, IExtendedAlbumSchema } from './albumsSlice'
import { getAlbumsItemsSelector } from './selectors'

export const getNumberBreakpoint = (breakpoint: string) => {
  return Number(breakpoint.replace(/[^0-9]/g, ""))
}

export const Albums = ({ className = '', type }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const albums = getAlbumsItemsSelector()
  const albumsPlaces = getAlbumsPlacesItemsSelector()
  const albumsSeasons = getAlbumsSeasonsItemsSelector()
  const albumsPerson = getAlbumsPersonWithNoneZeroContent()
  const albumsMoments = getAlbumsMomentsItemsSelector()

  const BREAKPOINTS = {
    POINT_767: getNumberBreakpoint(STYLED_VARIABLES.BREAKPOINTS.PHONE_90),
    POINT_1024: getNumberBreakpoint(STYLED_VARIABLES.BREAKPOINTS.TABLET),
    POINT_1280: getNumberBreakpoint(STYLED_VARIABLES.BREAKPOINTS.LAPTOP),
    POINT_1440: getNumberBreakpoint(STYLED_VARIABLES.BREAKPOINTS.DESKTOP)
  }
  const countsByBreakpoints = [
    [BREAKPOINTS.POINT_767, 2],
    [BREAKPOINTS.POINT_1024, 4],
    [BREAKPOINTS.POINT_1280, 5],
    [BREAKPOINTS.POINT_1440, 4]
  ]


  const setCountAlbumsPlaces = () => {
    const widthDisplay = document.documentElement.clientWidth
    for (const [threshold, count] of countsByBreakpoints) {
      if (widthDisplay <= threshold) {
        return count
      }
    }
    return 6
  }

  const countAlbumsRectungle = setCountAlbumsPlaces()

  const handleItemSelection = useCallback((id, isSelected) => {
    dispatch(albumsActions.updateItem({ id, selected: isSelected }))
  }, [])

  const handleCardClick = (album: IExtendedAlbumSchema) => {
    const link = type === SDK_TYPES.DEFAULT ? `/photos/albums/${album.id}` : `/family/photos/albums/${album.id}`

    navigate(link)
  }

  const handlePlaceCardClick = (album: IExtendedAlbumSchema) => {
    const link = SDK_TYPES.FAMILY === type ? `/family/photos/albums/places/${album.id}?backTo=albums` : `/photos/albums/places/${album.id}?backTo=albums`

    if (type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_PLACES_CLICK)
    } else {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_ALBUM_PLACES_CLICK)
    }

    navigate(link)
  }

  const handleMomentsCardClick = (album: IExtendedAlbumSchema) => {
    const link = SDK_TYPES.FAMILY === type ? `/family/photos/albums/moments/${album.id}?backTo=albums` : `/photos/albums/moments/${album.id}?backTo=albums`

    navigate(link)
  }

  const handleSeeAllMomentsClick = () => {
    const link = type === SDK_TYPES.DEFAULT ? '/photos/albums/moments' : '/family/photos/albums/moments'

    navigate(link)
  }

  const handleSeeAllPlacesClick = () => {
    const link = type === SDK_TYPES.DEFAULT ? '/photos/albums/places' : '/family/photos/albums/places'

    if (type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_PLACES_SEE_ALL_CLICK)
    } else {
      analytics.push(ANALYTICS_EVENTS.WEB_FC_ALBUM_PLACES_SEE_ALL_CLICK)
    }

    navigate(link)
  }

  const handleSeeAllSeasonsClick = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_ALBUM_SEASON_SEE_ALL_CLICK)

    navigate('/photos/albums/seasons')
  }

  const handleSeeAllPeopleClick = () => {
    if (type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_FACES_SEE_ALL_CLICK)
    }

    const peopleAlbumsPath = type === SDK_TYPES.DEFAULT ? '/photos/albums/people' : '/family/photos/albums/people'
    navigate(peopleAlbumsPath)
  }

  const handleClickAlbum = (id: string) => {
    if (type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_FACES_VIEW)
    }

    const albumPath = type === SDK_TYPES.DEFAULT ? `/photos/albums/people/${id}` : `/family/photos/albums/people/${id}`
    navigate(albumPath)
  }

  const getCountAlbumsSeasons = () => {
    const widthDisplay = document.documentElement.clientWidth
    if (widthDisplay >= getNumberBreakpoint(STYLED_VARIABLES.BREAKPOINTS.PHONE_90)) {
      return widthDisplay >= getNumberBreakpoint(STYLED_VARIABLES.BREAKPOINTS.DESKTOP) ? 3 : 2
    } else {
      return 2
    }
  }

  const totalCountAlbumsSeasons = Number(getAlbumsSeasonsTotalCountSelector())
  const totalCountAlbumsPlaces = Number(getAlbumsPlacesTotalCountSelector())
  const totalCountAlbumsMoments = albumsMoments.length

  return (
    <SScrollBox>
      {!!albumsSeasons.length && type === SDK_TYPES.DEFAULT && (
        <>
          <AlbumsPlacesTitle
            totalCount={totalCountAlbumsSeasons}
            textTitle={t('l_albums_memories')}
            countAlbums={getCountAlbumsSeasons()}
            textButton={t('a_albums_seeAll')}
            handleSeeAllClick={handleSeeAllSeasonsClick}
          />

          <AlbumsSeasons isAlbums={true}
            countAlbums={getCountAlbumsSeasons()}
          />
        </>
      )}

      {
        !!albumsPerson.length && (
          <AlbumsPersonList
            onClickSeeAll={handleSeeAllPeopleClick}
            onClickAlbum={handleClickAlbum}
          />
        )
      }

      {!!albumsMoments.length && (
        <AlbumsBox>
          <AlbumsPlacesTitle
            totalCount={totalCountAlbumsMoments}
            textTitle={t('l_albums_moments')}
            countAlbums={countAlbumsRectungle}
            textButton={t('a_albums_seeAll')}
            handleSeeAllClick={handleSeeAllMomentsClick}
          />

          <AlbumsPlacesGrid key={countAlbumsRectungle + 'moments'}
            isAlbumsPage={true}
          >
            {albumsMoments.map((album, index) => {
              if (index < countAlbumsRectungle)
                return  (
                  <AlbumPlaceCard
                    key={album.id}
                    id={album.id}
                    albumName={album.description || t('l_common_untitledAlbum')}
                    albumPhotoCount={t('l_common_countElements', { number: album.live_items_count })}
                    imgUrl={getPreviewAlbumLink(album,'middle')}
                    onClick={() => handleMomentsCardClick(album)}
                  />
                )
            })}
          </AlbumsPlacesGrid>
        </AlbumsBox>
      )}

      {!!albumsPlaces.length && (
        <AlbumsBox>
          <AlbumsMomentsTitle
            totalCount={totalCountAlbumsPlaces}
            textTitle={t('l_photos_searchPlaces')}
            countAlbums={countAlbumsRectungle}
            textButton={t('a_albums_seeAll')}
            handleSeeAllClick={handleSeeAllPlacesClick}
          />

          <AlbumsPlacesGrid key={countAlbumsRectungle + 'place'}
            isAlbumsPage={true}
          >
            {albumsPlaces.map((album, index) => {
              if (index < countAlbumsRectungle)
                return (
                  <AlbumPlaceCard
                    key={album.id}
                    id={album.id}
                    albumName={album.description || t('l_common_untitledAlbum')}
                    albumPhotoCount={t('l_common_countElements', { number: album.live_items_count })}
                    imgUrl={getPreviewAlbumLink(album,'middle')}
                    onClick={() => handlePlaceCardClick(album)}
                  />
                )
            })}
          </AlbumsPlacesGrid>
        </AlbumsBox>
      )}

      {!!albums.length && (
        <AlbumsBox className={className}>
          <TitleBlockBox>
            {t('a_nav_myAlbums')}
          </TitleBlockBox>

          <AlbumsGrid>
            {
              albums.map(album => (
                <AlbumCard
                  key={album.id}
                  id={album.id}
                  albumName={album.description || t('l_common_untitledAlbum')}
                  albumPhotoCount={t('l_common_countElements', { number: album.live_items_count })}
                  description={album.description}
                  onSelect={handleItemSelection}
                  isSelected={album.selected}
                  isShared={_.has(album._links,'share')}
                  imgUrl={getPreviewAlbumLink(album,'album')}
                  albumModified={getAlbumDateStr(album)}
                  onClick={() => handleCardClick(album)}
                />
              ))
            }
          </AlbumsGrid>
        </AlbumsBox>
      )}
    </SScrollBox>
  )
}

const SScrollBox = styled.div`
  height: calc(100% - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
  margin-right: -20px;
  
  @supports (-moz-appearance: none) {
    scrollbar-color: var(--scroll-color) transparent;
  }
  
  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    margin-right: unset;
  }

  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`

const TitleBlockBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-primary);
`

const AlbumsBox = styled.div`
  width: 100%;
  padding-bottom: 40px;
`
